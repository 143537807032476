import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Heading,
  Grid,
  HStack,
  Stack,
  Input,
  FormLabel,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Navigate, useLocation } from 'react-router-dom';
import { POST, PUT } from '../../../utilities/ApiProvider';

function TrainingFormApply() {
  const location = useLocation();
  const programDetails = location.state;
  const details = JSON.parse(localStorage.getItem('emediiUser') || '{}');
  const toast = useToast();
  console.log(programDetails);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      form.append('hospital', details?.hospital);
      form.append('studyProgram', programDetails?._id);
      const response = await POST('/admin/study-program/application', form);
      if (response.status === 200) {
        setLoading(false);

        toast({
          description: 'Application Submitted Successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        e.target.reset();
      } else {
        setLoading(false);

        toast({
          description: 'Application Submitted Failed',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAcceptReject = async _status => {
    try {
      const response = await PUT(
        `/admin/study-program/application/${programDetails?._id}`,
        { status: _status }
      );
      if (response.status === 200) {
        toast({
          description: `Application ${
            _status ? 'Accepted' : 'Rejected'
          } Successfully! `,
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        Navigate('/dashboard/requested-training-program');
      } else {
        toast({
          description: `Error occured while ${
            _status ? 'Accepting' : 'Rejecting'
          } Application`,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Apply Medical Education Training Program
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>
      <form className="applyForm" onSubmit={handleSubmit}>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Hospital Information:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="hospitalName"
              value={
                details?.type === 'superadmin'
                  ? programDetails?.hospital?.name
                  : details?.fullName
              }
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Address</FormLabel>
            <Input
              required
              name="hospitalAddress"
              value={
                details?.type === 'superadmin'
                  ? programDetails?.hospital?.address
                  : details?.address
              }
              type="text"
              placeholder="Enter Address"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="hospitalEmail"
              value={
                details?.type === 'superadmin'
                  ? programDetails?.hospital?.email
                  : details?.email
              }
              type="text"
              placeholder="Enter Email"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requesting Staff:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="staffName"
              defaultValue={programDetails?.staffName}
              type="text"
              placeholder="Enter Staff Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Mobile No.</FormLabel>
            <Input
              required
              name="staffMobileNo"
              defaultValue={programDetails?.staffMobileNo}
              type="number"
              placeholder="Enter Staff Number"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="staffEmail"
              defaultValue={programDetails?.staffEmail}
              type="text"
              placeholder="Enter Staff Email"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requested Program:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Program Name</FormLabel>
            <Input
              required
              name="programName"
              value={programDetails?.name || programDetails?.studyProgram?.name}
              type="text"
              placeholder="Enter Program Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Duration</FormLabel>
            <Input
              required
              name="programDuration"
              value={
                programDetails?.duration ||
                programDetails?.studyProgram?.duration
              }
              type="text"
              placeholder="Enter Program Duration"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Location</FormLabel>
            <Input
              required
              name="programLocation"
              value={
                programDetails?.location ||
                programDetails?.studyProgram?.location
              }
              type="text"
              placeholder="Enter Program Location"
            />
          </Box>
        </Stack>
        {!programDetails?.studyProgram?._id &&
          details?.type !== 'superadmin' && (
            <Button
              type="submit"
              bgGradient={'linear(to-r, #295377, #208C74)'}
              fontSize={'16px'}
              fontWeight={500}
              color={'#fff'}
              borderRadius={'25px'}
              isLoading={loading}
              h="45px"
              w="200px"
              border={'2px solid transparent'}
              _hover={{
                bgGradient: 'none',
                borderColor: 'primaryGreen.200',
                color: 'primaryGreen.200',
              }}
            >
              Apply Now
            </Button>
          )}
        {details?.type === 'superadmin' &&
          programDetails?.status === 'pending' && (
            <HStack
              w="50%"
              alignItems={'center'}
              justifyContent={'center'}
              gap={5}
              mt={4}
            >
              <Button
                m="0 !important"
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'16px'}
                fontWeight={500}
                color={'#fff'}
                w="100%"
                onClick={() => handleAcceptReject(true)}
                borderRadius={'25px'}
                cursor={'pointer'}
                h="45px"
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                Accept
              </Button>
              <Button
                m="0 !important"
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'16px'}
                fontWeight={500}
                color={'#fff'}
                cursor={'pointer'}
                w="100%"
                onClick={() => handleAcceptReject(false)}
                borderRadius={'25px'}
                h="45px"
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                Reject
              </Button>
            </HStack>
          )}
      </form>
    </Stack>
  );
}

export default TrainingFormApply;
