import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import LogoIcon from '../../../assets/images/website/logoIcon.jpg';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  Image,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  Checkbox,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Link,
  useDisclosure,
  Input,
  FormLabel,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import { useParams } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

function ElectronicHospitalRequest() {
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = useSelector(state => state.user.value);
  const { id } = useParams();
  const [minStartDate, setMinStartDate] = useState(
    new Date().toISOString().slice(0, 16)
  );
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const toast = useToast();
  const [checkedValues, setCheckedValues] = useState({
    website: true,
    socialMedia: false,
    emails: false,
  });
  const data = JSON.parse(localStorage.getItem('emediiUser'));

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    console.log(name, checked);

    setCheckedValues(() => {
      return {
        ...checkedValues,
        [name]: checked,
      };
    });
    console.log(checkedValues);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      if (id !== 'service') {
        form.append('service', id);
        form.append('hospital', user.hospital);
      } else {
        form.append('type', id);
        form.append('hospital', user.hospital);
      }
      const formDataEntries = Array.from(form.entries());
      console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));

      const response = await POST('/hospital/service', form);

      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Form Submitted SucessFully!!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (error) {
      console.log('Erorr while submitting form ', error);
    } finally {
      setLoading(false);
      e.target.reset();
    }
  };

  return (
    <Stack>
      <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={'12px'}>
          <ModalCloseButton />
          <ModalBody p="25px" className="modalBody">
            <Image src={LogoIcon} display={'flex'} mx="auto" />
            <Heading
              fontSize={'26px'}
              fontWeight={'500'}
              my="15px"
              transition={'.3s'}
              _groupHover={{ color: 'primaryBlack.100' }}
            >
              Terms & Conditions:
            </Heading>
            <Text fontSize={'14px'} color={'primaryGray.100'}>
              Thank you for choosing emedii.com as an intermediary to book the
              treatment services you need that are provided by hospitals through
              us, as well as to introduce you to the health practitioners who
              list their professional information and experience for you to
              review and choose the best that suits your needs.
            </Text>
            <Text fontSize={'14px'} color={'primaryGray.100'}>
              These terms and conditions may be amended from time to time;  they
              apply directly or indirectly (through distributors) to all our
              services available through our website, our platform, and any of
              our means. By completing the booking of any of our services, you
              decide and agree that you have read, understood, and agreed to the
              terms and conditions set forth below including the privacy policy,
              and that you are legally eligible to comply with the following: -
            </Text>
            <Text fontSize={'14px'} color={'primaryGray.100'}>
              (1)   The above preamble forms an integral part of the terms and
              conditions and privacy statement.
            </Text>
            <Text fontSize={'14px'} color={'primaryGray.100'}>
              (2)   Definitions
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.1 emedii.com: A platform and brokerage website for marketing a
              group of services in the health sector, aiming to provide the
              service to three categories (patients - hospitals - health
              practitioners). It belongs to the company (...) and its address is
              (……)
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.2 Patient, Visitor or User of the platform and website: Everyone
              who has been able to register with the platform and website and
              use its services provided by the hospitals through which they
              provide their services.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.3 Password and Username: These mean, respectively, a unique
              password and name issued by (emedii.com) to clients who use the
              platform and the site.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.4 Customer Data: This means all information collected about the
              customer, including his personal data.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.5 Data Protection: This means all laws, regulations, and any
              other legislation issued and in force to regulate the privacy,
              processing, and obtaining of personal information.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.6 Treatment Services: These mean the medical care provided by
              hospitals specializing in the treatment of certain diseases that
              require advanced equipment and are provided by highly specialized
              doctors.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.7 Data and Information Panel (Dashboard): This means the data
              and information related to each hospital and health practitioner
              that offers its services on the platform.
            </Text>
            <Text pl={'25px'} fontSize={'14px'} color={'primaryGray.100'}>
              2.8 Timetables: A pre-prepared model for clinic appointments
              generated by emedii.com. The hospital builds it with the
              appointments available to its doctors and according to which
              patients can book their appointments.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Electronic Advertisement Request for a Hospital
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all services...Check Now!
        </Text>
        {/* <Text fontSize={'20px'} fontWeight={'500'}>
              You've 5 services
            </Text> */}
      </Stack>
      {/*Heading  */}
      <form onSubmit={handleSubmit} className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Hospital Particulars:
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name</FormLabel>
            <Input
              value={data.fullName}
              required
              name="name"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Address</FormLabel>
            <Input
              required
              value={data.address}
              name="address"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              value={data.email}
              name="email"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Name of Requesting Staff</FormLabel>
            <Input
              required
              name="requestingStaffName"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Mobile No.</FormLabel>
            <Input
              required
              name="number"
              type="text"
              value={data?.number}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Advertisement Information:
        </Text>
        {/* <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Type of Advertisement</FormLabel>
            <Input
              required
              name="advertisementType"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Advertisement Duration</FormLabel>
            <Input
              required
              name="advertisementDuration"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Advertisement Display Time</FormLabel>
            <Input
              required
              name="advertisementDisplayTime"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack> */}
        <Box w="100%">
          <FormLabel>Text of Advertisement</FormLabel>
          {/* <Input
            required
            name="advertisementType"
            type="text"
            placeholder="Enter Name"
          /> */}
          <Textarea
            resize="none"
            name="advertisementText"
            placeholder="Enter Advertisement Text"
          />
        </Box>
        <Box w="100%">
          <FormLabel>Advertisement Keywords</FormLabel>
          <Input
            required
            name="advertisementKeywords"
            type="text"
            placeholder="Enter Keywords , multiple keywords with comma seperated"
          />
        </Box>
        <Box w="100%">
          <FormLabel>Advertisement Start Display Time</FormLabel>
          <Input
            required
            name="advertisementDurationStart"
            type="datetime-local"
            min={minStartDate}
          />
        </Box>
        <Box w="100%">
          <FormLabel>Advertisement End Display Time</FormLabel>
          <Input
            required
            name="advertisementDurationEnd"
            type="datetime-local"
            min={minStartDate}
          />
        </Box>
        <Box
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <FormLabel>Advertisement Image </FormLabel>
          <Input
            py={2.5}
            required
            accept=".jpg,.jpeg,.png,.gif"
            type="file"
            placeholder="Enter Name"
          />
        </Box>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Advertisement Display Means:
        </Text>
        <Stack pos={'relative'}>
          <Stack
            pos={'absolute'}
            m="0 !important"
            flexDirection={'row'}
            width={'100%'}
            top={-6}
            justifyContent={'end'}
            alignItems={'end'}
          >
            <Checkbox colorScheme="teal">All the above</Checkbox>
          </Stack>
        </Stack>
        <Box
          name="advertisementdisplayMeans"
          w="100%"
          className="checkboxFamily"
        >
          <Stack
            spacing={0}
            gap={4}
            direction={'row'}
            flexDir={{ base: 'column', xl: 'row' }}
            justifyContent={'space-between'}
          >
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="website"
                isChecked={checkedValues.website}
                defaultChecked={checkedValues.website}
                onChange={handleCheckboxChange}
              >
                emedst.com website
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="socialMedia"
                isChecked={checkedValues.socialMedia}
                defaultChecked={checkedValues.socialMedia}
                onChange={handleCheckboxChange}
              >
                emedst.com social media
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="emails"
                isChecked={checkedValues.emails}
                defaultChecked={checkedValues.emails}
                onChange={handleCheckboxChange}
              >
                emails by emedst.com to clients
              </Checkbox>
            </Box>
          </Stack>
        </Box>
        <Box w="100%" mb={'10px'}>
          <Box w="100%" mb="10px">
            <HStack alignItems="center" py="10px">
              <Checkbox
                colorScheme="blue"
                onChange={e => setAgreeToTerms(e.target.checked)}
              ></Checkbox>
              <Text fontSize="14px" color="primaryGray.100">
                I agree with{' '}
                <Link
                  color="blue.500"
                  cursor={'pointer'}
                  textDecoration="underline"
                  onClick={onOpen}
                >
                  "Terms & Conditions"
                </Link>
              </Text>
            </HStack>
          </Box>
        </Box>

        <Button
          w="200px"
          mt={'25px'}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          type="submit"
          isLoading={loading}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          disabled={!agreeToTerms}
        >
          Continue
        </Button>
      </form>
    </Stack>
  );
}

export default ElectronicHospitalRequest;
