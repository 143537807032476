import React, { useState, useEffect } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Divider,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  Image,
  RadioGroup,
  VStack,
  FormLabel,
  Select,
  Textarea,
  Checkbox,
  Input,
  useDisclosure,
  useToast,
  Radio,
  Flex,
  border,
  position,
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  ModalHeader,
} from '@chakra-ui/react';
import { CalendarIcon, AttachmentIcon } from '@chakra-ui/icons';
import ModalWrapper from '../../../components/Dashboard/Modal/index';
import Tick from '../../../assets/images/dashboard/tick.png';
import { GET, POST } from '../../../utilities/ApiProvider';
import moment from 'moment';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { MOYASAR_KEY } from '../../../utilities/config';
// const stripePromise = loadStripe(
//   'pk_test_51MuE4RJIWkcGZUIabXuoFFrr5gMT5S9Ynq63FfkoZMVeEkq94UdXOKwK4t3msKIsQwnLwafv9JyvzIdKpbsFonwd00BWb4lWdj'
// );

function ConfirmAppointment({
  onOpen,
  onClose,
  selectedSlot,
  doctor,
  selectedTime,
  isLoading,
  type,
  id,
  hospital,
  notes,
  selectedFile,
  travel,
}) {
  const { day, date } = selectedSlot;
  const { startTime, endTime } = selectedTime;
  const user = JSON.parse(localStorage.getItem('emediiUser'));
  let data = {
    type: type,
    day: day,
    date: date,
    startTime: new Date(`${date} ${selectedTime.startTime}`),
    endTime: new Date(`${date} ${selectedTime.endTime}`),
    doctor: id,
    hospital: hospital,
    notes: notes,
    reports: selectedFile,
    status: 'pending',
    doctorName: doctor?.name,
    charges: doctor?.charges,
    travel: travel === 'yes' ? true : false,
  };

  return (
    <Stack flexDirection={'column'} gap={2} lineHeight={'0.6rem'} mt={3}>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Booking No:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          03
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          MemberShip No:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {user?.memId || 'MID0001666'}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Booking Date:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {moment(new Date()).format('DD-MM-YYYY')}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Doctor Name:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          Dr. {doctor?.name || 'Adam Knight'}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Hospital Name:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {doctor?.hospital?.name}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Specialty:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {doctor?.generalSpeciality}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Appointment Day:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {day}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Appointment Date:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {moment(new Date(date)).format('DD-MM-YYYY')}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Appointment Time:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {startTime} to {endTime}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Examination Charge:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          SAR {doctor?.charges}
        </Text>
      </Stack>
      <Stack m={'10px 0px !important'}>
        <Button
          w="100%"
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'16px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          disabled={isLoading}
          onClick={() => {
            onClose();
            onOpen();
            localStorage.setItem('appointmentData', JSON.stringify(data));
          }}
        >
          Confirm Appointment
        </Button>
      </Stack>
    </Stack>
  );
}

// Through Stripe//
// function PaymentDetails({ onOpen, onClose, handleSubmit, isLoading, doctor }) {
//   async function createCardToken(data) {
//     try {
//       setLoading(true);
//       const response = await fetch('https://api.stripe.com/v1/tokens', {
//         method: 'POST',
//         headers: {
//           Authorization: `Bearer pk_test_51MuE4RJIWkcGZUIabXuoFFrr5gMT5S9Ynq63FfkoZMVeEkq94UdXOKwK4t3msKIsQwnLwafv9JyvzIdKpbsFonwd00BWb4lWdj`, // Use your Stripe publishable key
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: new URLSearchParams({
//           'card[number]': data.cardNumber, // Replace with actual card number input
//           'card[exp_month]': data.expiryMonth, // Replace with actual expiry month input
//           'card[exp_year]': data.expiryYear, // Replace with actual expiry year input (last two digits)
//           'card[cvc]': data.cvc, // Replace with actual CVC input
//         }),
//       });

//       const result = await response.json();

//       if (result.error) {
//         setLoading(false);
//         toast({
//           description: result?.error?.message,
//           status: 'error',
//           position: 'top-right',
//           duration: 3000,
//           isClosable: true,
//         });
//         return;
//       } else {
//         setLoading(false);
//         return result.id;
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       return error;
//     }
//   }

//   const handlePayment = async e => {
//     e.preventDefault();
//     try {
//       const form = new FormData(e.target);
//       const data = {
//         cardNumber: form.get('cardNumber'),
//         expiryMonth: form.get('expiryDate').split('/')[0],
//         expiryYear: form.get('expiryDate').split('/')[1],
//         cvc: form.get('cvc'),
//       };
//       const token = await createCardToken(data);

//       if (!token) {
//         console.log('Token creation failed.', token);
//       } else {
//         let dataToSend = {
//           token,
//           user: {
//             id: JSON.parse(localStorage.getItem('emediiUser'))._id,
//             name: JSON.parse(localStorage.getItem('emediiUser')).fullName,
//             email: JSON.parse(localStorage.getItem('emediiUser')).email,
//           },
//           amount: 50,
//           // amount: doctor?.charges,
//           doctor: doctor?._id,
//         };
//         const response = await POST('/admin/create-payment-intent', dataToSend);

//         if (response.status === 200 && response.data.status === 'succeeded') {
//           toast({
//             description: 'Your payment has been done  successful',
//             status: 'success',
//             position: 'top-right',
//             duration: 3000,
//             isClosable: true,
//           });
//           handleSubmit(onClose, onOpen);
//         } else {
//           toast({
//             description: 'Your Card has been declined',
//             status: 'error',
//             position: 'top-right',
//             duration: 3000,
//             isClosable: true,
//           });
//         }
//       }
//     } catch (err) {
//       console.log('Error:', err);
//     }
//   };
//   const [cardNumber, setCardNumber] = useState('');
//   const [cvc, setCvc] = useState('');
//   const [expiry, setExpiry] = useState('');
//   const toast = useToast();
//   const [loading, setLoading] = useState(false);
//   // Handle card number formatting
//   const handleCardNumberChange = e => {
//     let input = e.target.value.replace(/\D/g, '');
//     input = input.match(/.{1,4}/g)?.join(' ') || '';
//     setCardNumber(input);
//   };
//   // Handle CVC input
//   const handleCvcChange = e => {
//     const input = e.target.value.replace(/\D/g, '');
//     setCvc(input.slice(0, 4));
//   };

//   // Handle expiry date input
//   const handleExpiryChange = e => {
//     let input = e.target.value.replace(/\D/g, '');
//     if (input.length > 4) input = input.slice(0, 4);
//     if (input.length > 2) {
//       input = `${input.slice(0, 2)}/${input.slice(2)}`;
//     }
//     setExpiry(input);
//   };

//   return (
//     <form className="applyForm" onSubmit={handlePayment}>
//       <Heading textAlign={'center'} mb={5} fontSize={'32px'} fontWeight={'500'}>
//         Proceed with Payment
//       </Heading>
//       <Box>
//         <FormLabel>Enter Card Number</FormLabel>
//         <Input
//           type="text"
//           required
//           value={cardNumber}
//           onChange={handleCardNumberChange}
//           name="cardNumber"
//           placeholder="(XXXX XXXX XXXX XXXX)"
//           maxLength={19}
//         />
//       </Box>
//       <Box>
//         <FormLabel>Enter Card Holder Name</FormLabel>
//         <Input type="text" name="cardHolderName" placeholder="Enter Name" />
//       </Box>
//       <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
//         <Box w="48%">
//           <FormLabel>Expiry Date</FormLabel>
//           <Input
//             type="text"
//             required
//             value={expiry}
//             onChange={handleExpiryChange}
//             name="expiryDate"
//             placeholder="MM/YY"
//           />
//         </Box>
//         <Box w="48%">
//           <FormLabel>CVC</FormLabel>
//           <Input
//             required
//             type="text"
//             name="cvc"
//             value={cvc}
//             onChange={handleCvcChange}
//             maxLength={4}
//             placeholder="Enter CVC"
//           />
//         </Box>
//       </Stack>

//       {/* <Box>
//         <Checkbox colorScheme="teal" fontSize={'15px'} fontWeight={400}>
//           Save this card for future payment?{' '}
//         </Checkbox>
//       </Box> */}
//       <Box w="100%">
//         <Button
//           w="100%"
//           mt={'15px'}
//           bgGradient="linear(to-r, #295377, #208C74)"
//           color="#E9E9E9"
//           fontWeight={'500'}
//           fontSize={'15px'}
//           borderRadius={'21px'}
//           isLoading={loading || isLoading}
//           border={'2px solid transparent'}
//           _hover={{
//             bgGradient: 'none',
//             borderColor: 'primaryGreen.200',
//             color: 'primaryGreen.200',
//           }}
//           type="submit"
//         >
//           Continue & Pay
//         </Button>
//         {/* <Button
//           w="100%"
//           mt={'15px'}
//           bgGradient="linear(to-r, #295377, #208C74)"
//           color="#E9E9E9"
//           fontWeight={'500'}
//           fontSize={'15px'}
//           borderRadius={'21px'}
//           type="submit"
//           isLoading={isLoading}
//           border={'2px solid transparent'}
//           _hover={{
//             bgGradient: 'none',
//             borderColor: 'primaryGreen.200',
//             color: 'primaryGreen.200',
//           }}
//         >
//           <Stack
//             w="100%"
//             direction={'row'}
//             alignItems={'center'}
//             px={4}
//             justifyContent={'space-around'}
//           >
//             <Text fontSize={'16px'} fontWeight={'500'}>
//               Pay Now
//             </Text>
//             <Text fontSize={'16px'} fontWeight={'500'}>
//               {`$${doctor?.charges}`}
//             </Text>
//           </Stack>
//         </Button> */}
//       </Box>
//     </form>
//   );
// }
//End Stripe//
//Through Moyasar Payment//
function PaymentDetails({
  onOpen,
  onClose,
  handleSubmit,
  isLoading,
  doctor,
  url,
}) {
  const [isMoyasarLoaded, setIsMoyasarLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Moyasar) {
      setIsMoyasarLoaded(true);
    } else {
      const script = document.createElement('script');
      script.src = 'https://cdn.moyasar.com/mpf/1.14.0/moyasar.js';
      script.onload = () => setIsMoyasarLoaded(true);
      script.onerror = () => console.error('Failed to load Moyasar script');
      document.body.appendChild(script);
    }
  }, []);

  if (isMoyasarLoaded) {
    window.Moyasar.init({
      element: '.mysr-form',
      amount: doctor.charges * 100,
      currency: 'SAR',
      description: 'Appointment Booking..',
      publishable_api_key: MOYASAR_KEY,
      methods: ['creditcard'],
      callback_url: url,
      on_completed: async payment => {
        console.log('Payment Success:', payment);
      },
      on_error: error => {
        console.error('Payment Error:', error);
      },
    });
  }

  return (
    <Box>
      <Box className="mysr-form"></Box>
    </Box>
  );
}
//Through Moyasar Payment//
function CongratsModal({ doctor }) {
  const navigate = useNavigate();
  console.log(doctor);

  return (
    <Stack
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      gap={2}
      w={'100%'}
    >
      <Stack
        w="97px"
        h="97px"
        bgGradient="linear(to-r, #295377, #208C74)"
        borderRadius="50%"
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Image w="53px" h="53px" src={Tick} alt="tick" />
      </Stack>
      <Stack flexDirection={'column'} align={'center'}>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Congratulations!
        </Heading>
        <Text
          textAlign={'center'}
          fontSize={'15px'}
          fontWeight={'400'}
          color={'#75767A'}
        >
          Your appointment with Dr.{doctor?.name || 'John Doe'} . Once the
          hospital approved your appointment you will be notify.
        </Text>
      </Stack>
      <Stack w={'100%'} mb={'15px !important'}>
        <Button
          w="100%"
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          Done
        </Button>
      </Stack>
    </Stack>
  );
}
function HoursAvailableSlots({ timeSlots, setSelectedTime }) {
  const [selectedDayIndex, setSelectedDayIndex] = useState(null);
  const handleDaySelection = index => {
    setSelectedDayIndex(index);
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
      {timeSlots.map((val, index) => (
        <Stack
          border={'2px solid'}
          borderRadius={'12px'}
          borderColor={'primaryGreen.200'}
          p="4px 2px"
          w="100%"
          direction={'row'}
          alignItems={'center'}
          mb="0px !important"
        >
          <Text>
            <Checkbox
              colorScheme="teal"
              defaultChecked={val?.on}
              isChecked={selectedDayIndex === index}
              onChange={() => {
                handleDaySelection(index);
                setSelectedTime(val);
              }}
              // onChange={e => changeOnStatus(ind, e.target.checked)}
              mr="5px"
              mt="3px"
              transform="translateY(2px)"
            ></Checkbox>
          </Text>
          <Text
            bgColor={'primaryGreen.200'}
            borderRadius={'12px'}
            color={'#fff'}
          >
            <Input
              height={'42px !important'}
              borderColor={'primaryGreen.200 !important'}
              // onChange={e => changeStartingTime(ind, e.target.value)}
              type="text"
              defaultValue={val.startTime}
              value={val.startTime}
            />
          </Text>
          <Text
            bgColor={'primaryGreen.200'}
            borderRadius={'12px'}
            color={'#fff'}
          >
            <Input
              height={'42px !important'}
              borderColor={'primaryGreen.200 !important'}
              // onChange={e => changeEndingTime(ind, e.target.value)}
              type="text"
              defaultValue={val.endTime}
              value={val.endTime}
            />
          </Text>
        </Stack>
      ))}
    </Grid>
  );
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function BookAppointment() {
  const [selectedService, setSelectedService] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  const toast = useToast();
  const [daysSlots, setDaysSlots] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [travel, setTravel] = useState('');
  const [selectedTime, setSelectedTime] = useState({});
  const [notes, setNotes] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const query = useQuery();
  const hospital = query.get('hospital');
  const checkType = query.get('type');
  const patientType = query.get('patientType');
  const appointmentId = query.get('appointmentId');
  const paymenStatus = query.get('status');
  const paymenID = query.get('id');
  const paymentMessage = query.get('message');
  console.log(patientType, 'patientType');
  const url = window.location.href;
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();
  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();
  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onClose: onClose5,
  } = useDisclosure();
  const {
    isOpen: isOpen6,
    onOpen: onOpen6,
    onClose: onClose6,
  } = useDisclosure();
  const handleSlotClick = val => {
    setSelectedSlot(val);
    onOpen4();
  };
  const fetchSlots = async () => {
    try {
      const day = daysSlots?.find(item => item._id === selectedService)?.day;
      const response = await POST(
        `/web/patient/doctor/${id}/getAllAppointments?day=${day}`,
        {}
      );

      if (response.status === 200) {
        console.log(response.data);
        setTimeSlots(response.data);
      } else {
        setDaysSlots([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchDaysSlots = async () => {
      try {
        const response = await GET(`/web/patient/doctor/${id}/availability`);
        if (response.status === 200) {
          console.log(response.data);
          setSelectedService(response.data[0]?._id);
          setDaysSlots(response.data);
        } else {
          setDaysSlots([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    async function fetchDoctor() {
      try {
        const response = await GET(`/web/patient/doctor/${id}`);
        console.log(response);
        if (response.status === 200) setDoctor(response.data);
      } catch (error) {
        console.error('Error fetching doctor detail data:', error);
      }
    }
    fetchDoctor();

    fetchDaysSlots();
    console.log(paymenStatus, paymenID, paymentMessage);
    if (paymenStatus === 'paid' && paymenID && paymentMessage === 'APPROVED') {
      onOpen6();
      handleSubmit(onClose6, onOpen3, paymenID);
    } else if (paymenStatus === 'failed') {
      localStorage.removeItem('appointmentData');

      const url = new URL(window.location.href);
      url.searchParams.delete('id');
      url.searchParams.delete('status');
      url.searchParams.delete('amount');
      url.searchParams.delete('message');

      window.history.replaceState({}, document.title, url.toString());

      toast({
        description: paymentMessage,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }, []);
  useEffect(() => {
    fetchSlots();
  }, [selectedService]);

  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };
  const validateFields = () => {
    let missingFields = [];

    // Retrieve patientType from query params (assuming you have access to query parameters)
    const patientType = query.get('patientType');

    // Basic required fields validation
    if (!patientType) missingFields.push('Patient Type');
    if (!selectedSlot) missingFields.push('Available Slots');
    if (!selectedTime) missingFields.push('Selected Time');
    if (!id) missingFields.push('Doctor ID');
    if (!hospital) missingFields.push('Hospital');
    if (!notes) missingFields.push('Notes');

    // Validate selectedFile based on patientType
    if (
      patientType === 'virtual' &&
      !selectedFile &&
      checkType !== 'reschedule'
    ) {
      missingFields.push('Upload Reports');
    }

    // In-person appointments do not require a file
    if (patientType === 'in-person') {
      // No file required, so clear any 'Upload Reports' from missingFields if present
      missingFields = missingFields.filter(field => field !== 'Upload Reports');
    }

    // Show validation error if there are missing fields
    if (missingFields.length > 0) {
      const missingFieldsText = missingFields.join(', ');

      console.log('Please fill in all the required fields.');

      // Show toast notification for missing fields
      toast({
        title: 'Validation Error',
        description: `Please fill following fields: ${missingFieldsText}.`,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });

      return false; // Return false if validation fails
    }

    return true; // Return true if all fields are valid
  };

  const handleSubmit = async (onClose, onOpen, paymenID) => {
    // Provide default values if selectedSlot is null or undefined
    const { day = '', date = '' } = selectedSlot || {};
    const data = JSON.parse(localStorage.getItem('appointmentData'));
    let travel = data?.travel;
    if (!checkType) {
      setIsLoading(true);
      try {
        const form = new FormData();
        form.append('type', data?.type);
        form.append('day', data?.day);
        form.append('startTime', new Date(`${data?.startTime}`));
        form.append('endTime', new Date(`${data?.endTime}`));
        form.append('doctor', data?.doctor);
        form.append('hospital', data?.hospital);
        form.append('notes', data?.notes);
        form.append('reports', data?.selectedFile);
        form.append('status', 'pending');
        form.append('doctorName', data?.doctorName);
        form.append('charges', data?.charges);

        // form.append('hospitalDetails', hospital)
        // console.log({doctor: doctor?.charges})

        form.append('travel', travel === 'yes' ? true : false);
        form.append('paymentId', paymenID);
        form.append('paymentStatus', 'paid');
        // Log form entries for debugging

        // console.log(
        //   Array.from(form.entries()).map(([key, value]) => ({ [key]: value }))
        // );

        const response = await POST(
          `/web/patient/doctor/${id}/book-appointment/${hospital}`,
          form
        );
        console.log(response);
        if (response.status === 200) {
          localStorage.removeItem('appointmentData');
          setIsLoading(false);

          onClose();
          onOpen();
        } else {
          localStorage.removeItem('appointmentData');

          setIsLoading(false);
        }
      } catch (error) {
        localStorage.removeItem('appointmentData');

        setIsLoading(false);

        console.log('Error while submitting form', error);
      }
    } else if (checkType === 'reschedule' || checkType === 'rebook') {
      try {
        const data = {
          reason: notes,
          availability: {
            day: day,
            time: `${selectedTime.startTime} - ${selectedTime.endTime}`,
            startTime: new Date(`${date} ${selectedTime.startTime}`),
            endTime: new Date(`${date} ${selectedTime.endTime}`),
          },
        };
        console.log(data);

        const endpoint =
          checkType === 'reschedule'
            ? `/web/patient/appointments/${appointmentId}/reschedule`
            : `/web/patient/appointments/${appointmentId}/rebook`;

        const response = await POST(endpoint, data);
        console.log(response);
        if (response.status === 200) {
          onClose();
          onOpen();
        }
      } catch (error) {
        console.log('Error while submitting form', error);
      }
    }
  };

  return (
    <Stack>
      {/*Heading  */}
      <ModalWrapper
        isOpen={isOpen1}
        onClose={onClose1}
        title={'Confirm Appointment'}
        subTitle={'In-person Clinic Appointment Booking'}
        children={
          <ConfirmAppointment
            onOpen={onOpen5}
            onClose={onClose1}
            selectedSlot={selectedSlot}
            doctor={doctor}
            selectedTime={selectedTime}
            // handleSubmit={handleSubmit}
            isLoading={isLoading}
            type={patientType}
            id={id}
            hospital={hospital}
            notes={notes}
            selectedFile={selectedFile}
            travel={travel}
          />
        }
      />

      {/* <Modal bg="transparent" isCentered isOpen={isOpen6} onClose={onClose6}>
        <ModalOverlay backdropFilter="blur(5px)" />
        <ModalContent
          display="flex"
          justifyContent={'center'}
          bg="transparent"
          border="none"
          outline="none"
          w="100%"
        >
          <Stack justifyContent="center" spacing={4} w={'100%'}>
            <Spinner color="#fff" size="xl" />
          </Stack>
        </ModalContent>
      </Modal> */}
      <Modal isCentered isOpen={isOpen6} onClose={onClose6}>
        <ModalOverlay
          bg="blackAlpha.300 !important"
          // zIndex={'10'}
          backdropFilter="blur(1px) grayscale(1)"
        />
        <ModalContent
          className=" !bg-transparent"
          bg={'transaprent'}
          shadow={'none'}
        >
          <ModalHeader textAlign={'center'}>
            <Spinner size="xl" color={'#fff'} thickness={'4px'} />
          </ModalHeader>
        </ModalContent>
      </Modal>
      <ModalWrapper
        isOpen={isOpen3}
        onClose={onClose3}
        children={<CongratsModal doctor={doctor} />}
      />
      <ModalWrapper
        isOpen={isOpen5}
        onClose={onClose5}
        children={
          <PaymentDetails
            onOpen={onOpen3}
            onClose={onClose5}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            doctor={doctor}
            url={url}
          />
        }
      />
      <ModalWrapper
        isOpen={isOpen4}
        onClose={onClose4}
        size={'xl'}
        children={
          <HoursAvailableSlots
            timeSlots={timeSlots}
            setSelectedTime={setSelectedTime}
          />
        }
      />
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          {checkType === 'reschedule'
            ? 'Re-schedule Appointment'
            : checkType === 'rebook'
            ? 'Re-book Appointment'
            : 'Book Appointment'}{' '}
          /{' '}
          <Text
            mt={'10px'}
            ml={'8px'}
            color={'#208C74'}
            fontSize={'20px'}
            fontWeight={'500'}
          >
            Health Practitioner Profile
          </Text>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the details of health practitioner
        </Text>
      </Stack>
      {/*Heading  */}
      <Stack>
        <Text fontSize={'18px'} fontWeight={400}>
          Choose Time
        </Text>

        <Stack direction={'row'} gap={4}>
          {daysSlots?.map((val, ind) => (
            <Button
              w="100%"
              m="0 !important"
              bgGradient={
                selectedService === val?._id
                  ? 'linear(to-r, #295377, #208C74)'
                  : 'linear(to-b, #C2D4FF, #fff)'
              }
              fontSize={{ base: '12px', xl: '16px' }}
              fontWeight={500}
              color={selectedService === val?._id ? '#fff' : 'primaryBlack.100'}
              borderRadius={'6px'}
              h={{ base: '81px', xl: '81px' }}
              onClick={() => setSelectedService(val?._id)}
              _hover={{
                bgGradient: 'linear(to-r, #295377, #208C74)',
                color: '#fff',
              }}
            >
              <VStack alignItems={'start'} justifyContent={'start'}>
                <Text
                  fontSize={'21px'}
                  fontWeight={600}
                  color={
                    selectedService === val?._id ? '#fff' : 'primaryBlack.100'
                  }
                >
                  {val?.day}
                </Text>
                <Text
                  fontSize={'18px'}
                  fontWeight={500}
                  color={
                    selectedService === val?._id ? '#fff' : 'primaryBlack.100'
                  }
                >
                  {val?.startTime} to {val?.endTime} PM
                </Text>
              </VStack>
            </Button>
          ))}
        </Stack>
      </Stack>
      <Stack my={'20px !important'}>
        <Text fontSize={'18px'} fontWeight={400}>
          Available Slots
        </Text>
      </Stack>
      <Stack direction={'row'} gap={4} alignItems={'start'} flexWrap={'wrap'}>
        {daysSlots
          ?.filter(item => item._id === selectedService)?.[0]
          ?.['availableSlots']?.map((val, index) => (
            <Box
              w={'48%'}
              h="66px"
              border={'1px solid '}
              borderRadius={'11px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              m={'0 !important'}
              onClick={() => handleSlotClick(val)}
            >
              <Stack
                w="100%"
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={3}
                margin={'0 10px'}
              >
                <Stack direction={'row'} gap={3}>
                  <Box
                    w="45px"
                    h="50px"
                    borderRadius={'6px'}
                    bgGradient="linear(to-r, #295377, #208C74)"
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <CalendarIcon color={'#fff'} w={'20px'} h={'20px'} />
                  </Box>
                  <Stack>
                    <Text fontSize={'16px'} fontWeight={600}>
                      {val?.date}
                    </Text>
                    <Text fontSize={'14px'} m={'0 !important'} fontWeight={400}>
                      {val?.day}
                    </Text>
                  </Stack>
                </Stack>
                <Stack>
                  <Radio
                    isChecked={selectedSlot === val}
                    colorScheme="green"
                    value="2"
                    size={'lg'}
                  ></Radio>
                </Stack>
              </Stack>
            </Box>
          ))}
      </Stack>

      <Stack my={'10px !important'}>
        <Text fontSize={'18px'} fontWeight={400}>
          {checkType === 'reschedule'
            ? 'Reason for reschedule'
            : checkType === 'rebook'
            ? 'Reason for rebook'
            : 'Notes'}
        </Text>
        {checkType !== 'reschedule' ||
          (checkType !== 'rebook' && (
            <Text color={'#75767A'} fontSize={'15px'} fontWeight={400}>
              Leave Notes for Health Practitioner
            </Text>
          ))}
        <Textarea
          placeholder="Enter Notes..."
          height={'117px'}
          borderRadius={'14px'}
          name="description"
          resize={'none'}
          onChange={e => setNotes(e.target.value)}
          border={'1px solid #75767A !important'}
          required
        ></Textarea>
      </Stack>
      {checkType !== 'reschedule' && checkType !== 'rebook' && (
        <Stack mb={'10px !important'}>
          <Box w="100%">
            <FormLabel color={'#208C74'}>Upload Reports</FormLabel>

            <Text fontSize="14px" color="primaryGray.100">
              <Input
                name="attachment"
                type="file"
                id="file-input"
                accept=".pdf,.jpeg,.jpg,.png,.doc,.docx"
                display="none"
                onChange={handleFileChange}
              />
              <Button
                cursor={'pointer'}
                as="span"
                border="1px solid #75767A"
                bgColor="transparent"
                fontSize="14px"
                color="primaryGray.100"
                transition=".3s"
                _hover={{
                  bgColor: 'primaryGreen.200',
                  color: '#fff',
                  borderColor: 'primaryGreen.200',
                }}
                mr="10px"
                onClick={handleButtonClick}
              >
                <AttachmentIcon mr="10px" /> Attach File
              </Button>
              {selectedFile ? selectedFile.name : 'No File chosen'}
            </Text>
            <Text fontSize="sm" color="gray.500" mt={2}>
              Allowed formats: PDF, JPG, PNG, DOC, DOCX
            </Text>
          </Box>
        </Stack>
      )}

      {patientType === 'inperson' && (
        <Stack mb={'10px !important'}>
          <Text fontSize="16px" fontWeight={'400'} color="#208C74">
            Would you like to avail a travel service for your appointment?"
          </Text>
          <RadioGroup onChange={setTravel} value={travel}>
            <Stack direction="row" alignItems={'center'} gap={4}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Stack>
          </RadioGroup>
        </Stack>
      )}

      <Stack>
        <Button
          w="220px"
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'16px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          onClick={() => {
            if (validateFields()) {
              onOpen1(); // Open modal only if all fields are valid
            } else {
              // Optionally, show a toast or error message
              console.log('Form validation failed.');
            }
          }}
        >
          {checkType === 'reschedule'
            ? 'Re-schedule Appointment'
            : checkType === 'rebook'
            ? 'Re-book Appointment'
            : 'Confirm Appointment'}
        </Button>
      </Stack>
    </Stack>
  );
}

export default BookAppointment;
