import React, { useState } from 'react';
import {
  Grid,
  GridItem,
  Box,
  HStack,
  Stack,
  Avatar,
  Heading,
  Text,
  WrapItem,
  Button,
  Divider,
  Badge,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  Input,
  useDisclosure,
  Select,
  Textarea,
  Checkbox,
  useToast,
  Icon,
} from '@chakra-ui/react';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment';
import { POST, PUT } from '../../../utilities/ApiProvider';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegStar } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';

function CancelAppointment({ id, onClose, fetchAppointments }) {
  const toast = useToast();
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const form = new FormData(e.target);
      form.append('status', 'cancelled');
      const response = await PUT(`/hospital/appointments/${id}/cancel`, form);
      if (response.status === 200) {
        toast({
          title: 'Appointment Cancelled',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
        fetchAppointments();
      }
    } catch (error) {
      console.log('Error while canceling', error);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="applyForm">
        <Box mb="10px">
          <FormLabel>Choose an option</FormLabel>
          <Select name="reason">
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
        </Box>
        <Box mb="20px !important">
          <FormLabel>Describe your issue</FormLabel>
          <Textarea
            name="issue"
            // border="1px solid #75767A"
            // borderRadius="12px"
            resize={'none'}
            placeholder="Here is a sample placeholder"
          />
        </Box>
        <Box>
          <Button
            w="100%"
            mt="10px"
            bgGradient="linear(to-r, #295377, #208C74)"
            color="#E9E9E9"
            fontWeight={'500'}
            fontSize={'16px'}
            borderRadius={'21px'}
            type="submit"
          >
            Send
          </Button>
        </Box>
      </form>
    </>
  );
}

function RescheduleAppointment({ id, onClose, fetchAppointments }) {
  const [openingHours, setOpeningHours] = useState([
    {
      on: true,
      day: 'Monday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Tuesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Wednesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Thursday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Friday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Saturday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Sunday',
      startTime: '09:00',
      endTime: '22:00',
    },
  ]);
  const [selectedDayIndex, setSelectedDayIndex] = useState(null);
  const [reason, setReason] = useState('');
  const toast = useToast();
  const handleDaySelection = index => {
    setSelectedDayIndex(index);
  };

  const handleRequestSend = async () => {
    const selectedDay = openingHours[selectedDayIndex];
    try {
      const response = await POST(`/hospital/appointments/${id}/reschedule`, {
        reasons: reason,
        availability: [selectedDay],
        isReschedule: true,
        status: 'pending',
      });
      if (response.status === 200) {
        fetchAppointments();
        toast({
          title: 'Rescheduled successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      }
    } catch (error) {
      console.log('Erorr while rescheduling', error);
    }
  };
  return (
    <>
      <form className="applyForm">
        <Box mb="15px !important">
          <FormLabel>Reason of reschedule</FormLabel>
          <Textarea
            onChange={e => setReason(e.target.value)}
            resize={'none'}
            placeholder="Here is a sample placeholder"
          />
        </Box>
        <Box mb="10px">
          <FormLabel>Choose Availability</FormLabel>
          {openingHours.map((val, index) => (
            <Stack
              border={'2px solid'}
              borderRadius={'12px'}
              borderColor={!val.on ? '#75767A' : 'primaryGreen.200'}
              p="5px 5px 5px 15px"
              key={val.day}
              direction={'row'}
              alignItems={'center'}
              mb="0px !important"
            >
              <Text flex={'1'}>
                <Checkbox
                  colorScheme="teal"
                  defaultChecked={val?.on}
                  isChecked={selectedDayIndex === index}
                  onChange={() => handleDaySelection(index)}
                  // onChange={e => changeOnStatus(ind, e.target.checked)}
                  mr="5px"
                  mt="3px"
                  transform="translateY(2px)"
                ></Checkbox>
                {val.day}:
              </Text>
              <Text
                bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                borderRadius={'12px'}
                color={'#fff'}
              >
                <Input
                  isDisabled={!val.on}
                  height={'42px !important'}
                  borderColor={'primaryGreen.200 !important'}
                  // onChange={e => changeStartingTime(ind, e.target.value)}
                  type="time"
                  defaultValue={val.startTime}
                  value={val.startTime}
                />
              </Text>
              <Text
                bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                borderRadius={'12px'}
                color={'#fff'}
              >
                <Input
                  isDisabled={!val.on}
                  height={'42px !important'}
                  borderColor={'primaryGreen.200 !important'}
                  // onChange={e => changeEndingTime(ind, e.target.value)}
                  type="time"
                  defaultValue={val.endTime}
                  value={val.endTime}
                />
              </Text>
            </Stack>
          ))}
        </Box>
        <Box mb="15px !important">
          <Button
            w="100%"
            onClick={handleRequestSend}
            mt={'10px'}
            bgGradient="linear(to-r, #295377, #208C74)"
            color="#E9E9E9"
            fontWeight={'500'}
            fontSize={'16px'}
            borderRadius={'21px'}
          >
            Send Request
          </Button>
        </Box>
      </form>
    </>
  );
}
function BasicUsage({ isOpen, onClose, title, subTitle, children }) {
  return (
    <>
      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader margin={'0 auto'} fontSize={'26px'} fontWeight={'500'}>
            {title}
            <Text color={'#75767A'} fontSize={'14px'} fontWeight={'400'}>
              {subTitle}
            </Text>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
function AddReview({ data, onClose }) {
  console.log(data);
  const [rating, setRating] = useState([
    { id: 1, isCheck: false },
    { id: 2, isCheck: false },
    { id: 3, isCheck: false },
    { id: 4, isCheck: false },
    { id: 5, isCheck: false },
  ]);
  const [doctorrating, setDoctorRating] = useState([
    { id: 1, isCheck: false },
    { id: 2, isCheck: false },
    { id: 3, isCheck: false },
    { id: 4, isCheck: false },
    { id: 5, isCheck: false },
  ]);
  const [travelrating, setTravelRating] = useState([
    { id: 1, isCheck: false },
    { id: 2, isCheck: false },
    { id: 3, isCheck: false },
    { id: 4, isCheck: false },
    { id: 5, isCheck: false },
  ]);
  const toast = useToast();
  const [doctorFeedback, setDoctorFeedback] = useState('');
  const [hospitalFeedback, setHospitalFeedback] = useState('');
  const [travelFeedback, setTravelFeedback] = useState('');
  const handleSend = async () => {
    try {
      const form = new FormData();
      form.append(
        'hospitalRating',
        rating.filter(item => item.isCheck)?.length
      );
      form.append(
        'doctorRating',
        doctorrating.filter(item => item.isCheck)?.length
      );
      form.append(
        'travelRating',
        travelrating.filter(item => item.isCheck)?.length
      );
      form.append('hospitalFeedback', hospitalFeedback);
      form.append('doctorFeedback', doctorFeedback);
      form.append('travelFeedback', travelFeedback);
      form.append('hospital', data?.hospital?._id);
      form.append('doctor', data?.doctorId);
      form.append('category', '');
      const response = await POST('/web/patient/review', form);
      if (response.status === 200) {
        toast({
          title: 'Review Added',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      }
    } catch (error) {
      console.log('Error while sending review', error);
    }
  };
  return (
    <Stack display={'flex'} flexDirection={'column'}>
      <Text
        fontSize={'18px'}
        fontWeight={500}
        p={'0 !important'}
        m={'0 !important'}
      >
        Service Feedback:
      </Text>
      <Text
        fontSize={'14px'}
        fontWeight={400}
        p={'0 !important'}
        m={'0 !important'}
      >
        May 22, 2023 - 10.00 AM
      </Text>
      <Stack display={'flex'} flexDirection={'row'} gap={4}>
        <Stack>
          <Avatar
            size="xl"
            name="Kent Dodds"
            borderRadius={'12px'}
            w={'79px'}
            h={'79px'}
            src={
              `${imgUrl}/${data?.assignedDoctorPhoto}` ||
              'https://bit.ly/kent-c-dodds'
            }
          ></Avatar>
        </Stack>
        <Stack>
          <Text
            p={'0 !important'}
            m={'0 !important'}
            fontSize={'17px'}
            fontWeight={700}
          >
            Dr. {data?.assignedDoctor}
          </Text>
          <Text
            p={'0 !important'}
            m={'0 !important'}
            fontSize={'15px'}
            color={'#208C74'}
            fontWeight={600}
          >
            {data?.assignedDoctorGeneralSpeciality}
          </Text>
          <Text
            p={'0 !important'}
            m={'0 !important'}
            fontSize={'15px'}
            fontWeight={400}
            color={'#4B5563'}
          >
            {data?.hospital?.address}
          </Text>
        </Stack>
      </Stack>
      <Text mt={4} color={'#75767A'} fontSize={'15px'} fontWeight={400}>
        Rate the to the recent service
      </Text>

      <Stack
        display={'flex'}
        flexDirection={'row'}
        gap={6}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {rating?.map((item, index) => (
          <Icon
            as={item?.isCheck ? FaStar : FaRegStar}
            m="0 !important"
            p="0 !important"
            w="55px"
            h="55px"
            onClick={() =>
              setRating(
                rating.map(val =>
                  val.id === index + 1 ? { ...val, isCheck: !val.isCheck } : val
                )
              )
            }
            color="#FEB052"
          />
        ))}
      </Stack>
      <Textarea
        placeholder="Detail Feedback..."
        height={'117px'}
        borderRadius={'14px'}
        name="description"
        onChange={e => setHospitalFeedback(e.target.value)}
        resize={'none'}
        border={'1px solid #75767A'}
        required
      ></Textarea>

      <Text mt={4} fontSize={'18px'} fontWeight={500}>
        Health Practitioner Feedback:
      </Text>
      <Text mt={4} color={'#75767A'} fontSize={'15px'} fontWeight={400}>
        Rate the to the recent service
      </Text>

      <Stack
        display={'flex'}
        flexDirection={'row'}
        gap={6}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {doctorrating?.map((item, index) => (
          <Icon
            as={item?.isCheck ? FaStar : FaRegStar}
            m="0 !important"
            p="0 !important"
            w="55px"
            h="55px"
            onClick={() =>
              setDoctorRating(
                doctorrating.map(val =>
                  val.id === index + 1 ? { ...val, isCheck: !val.isCheck } : val
                )
              )
            }
            color="#FEB052"
          />
        ))}
      </Stack>
      <Textarea
        placeholder="Detail Feedback..."
        height={'117px'}
        borderRadius={'14px'}
        onChange={e => setDoctorFeedback(e.target.value)}
        name="description"
        resize={'none'}
        border={'1px solid #75767A'}
        required
      ></Textarea>

      {data?.travel && (
        <>
          <Text mt={4} fontSize={'18px'} fontWeight={500}>
            Travel Service Feedback:
          </Text>
          <Text mt={4} color={'#75767A'} fontSize={'15px'} fontWeight={400}>
            Rate the to the recent service
          </Text>

          <Stack
            display={'flex'}
            flexDirection={'row'}
            gap={6}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {travelrating?.map((item, index) => (
              <Icon
                as={item?.isCheck ? FaStar : FaRegStar}
                m="0 !important"
                p="0 !important"
                w="55px"
                h="55px"
                onClick={() =>
                  setTravelRating(
                    travelrating.map(val =>
                      val.id === index + 1
                        ? { ...val, isCheck: !val.isCheck }
                        : val
                    )
                  )
                }
                color="#FEB052"
              />
            ))}
          </Stack>
          <Textarea
            placeholder="Detail Feedback..."
            height={'117px'}
            borderRadius={'14px'}
            onChange={e => setTravelFeedback(e.target.value)}
            name="description"
            resize={'none'}
            border={'1px solid #75767A'}
            required
          ></Textarea>
        </>
      )}

      <Stack my={'15px !important'}>
        <Button
          w="100%"
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'16px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          onClick={handleSend}
        >
          Send
        </Button>
      </Stack>
    </Stack>
  );
}
function AppointmentBox({ type, selectedService, data, fetchAppointments }) {
  console.log(data);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAddReview,
    onOpen: onOpenAddReview,
    onClose: onCloseAddReview,
  } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedId, setId] = useState('');
  const navigate = useNavigate();
  const handleAccept = async (id, value) => {
    setLoading(false);
    try {
      if (!id || !value) return;
      const response = await POST(`/hospital/appointments/${id}/action`, {
        status: 'ongoing',
      });
      if (response.status === 200) {
        console.log(response);
        fetchAppointments();
        toast({
          description: `Appointment ${value} successfully`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  const isSevenDaysRemaining = appointmentDate => {
    console.log(appointmentDate);
    const today = new Date();
    const appointment = new Date(appointmentDate);

    const diffInTime = appointment.getTime() - today.getTime();
    const daysRemaining = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

    return daysRemaining >= 7;
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      {selectedService === 'pending' ? (
        <BasicUsage
          isOpen={isOpen}
          onClose={onClose}
          title={'Cancel Appointment'}
          subTitle={'Do you want to “Cancel” this appointment?'}
          children={
            <CancelAppointment
              id={selectedId}
              onClose={onClose}
              fetchAppointments={fetchAppointments}
            />
          }
        />
      ) : (
        <BasicUsage
          isOpen={isOpen}
          onClose={onClose}
          title={'Re-schedule Appointment'}
          subTitle={'Enter the details to add new service'}
          children={
            <RescheduleAppointment
              id={selectedId}
              onClose={onClose}
              fetchAppointments={fetchAppointments}
            />
          }
        />
      )}
      {type === 'patient' && selectedService === 'pending' ? (
        <BasicUsage
          isOpen={isOpen}
          onClose={onClose}
          title={'Cancel Appointment'}
          subTitle={'Do you want to “Cancel” this appointment?'}
          children={
            <CancelAppointment
              id={selectedId}
              onClose={onClose}
              fetchAppointments={fetchAppointments}
            />
          }
        />
      ) : (
        type === 'patient' &&
        selectedService === 'completed' && (
          <BasicUsage
            isOpen={isOpenAddReview}
            onClose={onCloseAddReview}
            title={'Add Review'}
            subTitle={'Enter the details to add review'}
            children={
              <AddReview
                data={data.filter(item => item._id === selectedId)[0]}
                onClose={onCloseAddReview}
              />
            }
          />
        )
      )}

      {data.length > 0 ? (
        data?.map((item, index) => (
          <>
            <Box
              key={index}
              shadow={'lg'}
              w="100%"
              borderRadius={'16px'}
              bg={'#fff'}
              p={5}
            >
              <HStack justifyContent={'space-between'}>
                <HStack alignItems={'center'}>
                  <WrapItem>
                    <Avatar
                      size="lg"
                      name="Prosper Otemuyiwa"
                      src={`${imgUrl}/${item.patientPicture}`}
                    />{' '}
                  </WrapItem>
                  <Stack justifyContent={'center'}>
                    <Heading fontWeight={'400'} fontSize={'22px'}>
                      {item.patientName || 'John Doe'}
                    </Heading>
                    <Text
                      lineHeight={0}
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      #d44vdsQ
                    </Text>
                  </Stack>
                </HStack>
                <Stack>
                  {(selectedService === 'cancel' ||
                    selectedService === 'completed') && (
                    <Badge
                      w="90px"
                      height="28px"
                      display={'flex'}
                      alignItems={'center'}
                      borderRadius={'50px'}
                      justifyContent={'center'}
                      bg={selectedService === 'cancel' ? '#D03636' : '#47B25F'}
                      color={'#fff'}
                    >
                      {selectedService === 'cancel' ? 'Cancelled' : 'Completed'}
                    </Badge>
                  )}
                  {item?.paymentStatus === 'paid' && (
                    <Badge
                      w="90px"
                      height="28px"
                      display={'flex'}
                      alignItems={'center'}
                      borderRadius={'50px'}
                      justifyContent={'center'}
                      bg={'#47B25F'}
                      color={'#fff'}
                    >
                      Paid
                    </Badge>
                  )}
                </Stack>
              </HStack>
              <Stack gap={4} mt={4}>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Appointment Date
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {moment(item.endTime).format('M/D/YYYY') || '2/27/2024'}
                    </Text>
                  </Stack>

                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Patient Type
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {item.type || 'Consultation'}
                    </Text>
                  </Stack>

                  <Stack flex={1}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#75767A'}
                    >
                      Assigned Doctor
                    </Text>

                    <HStack marginTop={'2px'}>
                      <Avatar
                        size="sm"
                        name="Kent Dodds"
                        src={
                          `${imgUrl}/${item.assignedDoctorPhoto}` ||
                          'https://bit.ly/kent-c-dodds'
                        }
                      />{' '}
                      <Text
                        lineHeight={'5px'}
                        fontWeight={'400'}
                        color={'#1B1C1D'}
                      >
                        {item.assignedDoctor || 'Adam Knight'}
                      </Text>
                    </HStack>
                  </Stack>
                </Stack>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      SAR Price
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      SAR {item.assignedDoctorCharges || '120.00'}
                    </Text>
                  </Stack>

                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Time
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {moment(item.endTime).format('h - mm A') || ' 3 - 30 PM'}
                    </Text>
                  </Stack>

                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Patient Age
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {item.patientage || '27'}
                    </Text>
                  </Stack>
                </Stack>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Transaction Id
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {item?.paymentId || '123456789'}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
              {/* Patient */}
              {selectedService === 'pending' &&
                type === 'patient' &&
                isSevenDaysRemaining(item.endTime) && (
                  <HStack mt={8} mb={2}>
                    <Button
                      w="100%"
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'16px'}
                      borderRadius={'21px'}
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                      as={Link}
                      to={`/dashboard/book-appointment/${item.doctorId}?type=reschedule&appointmentId=${item._id}`}
                    >
                      Re-schedule
                    </Button>
                    <Button
                      w="100%"
                      shadow={'0px 16px 38px 0px #0000000F'}
                      border={'1px solid #75767A'}
                      color="#75767A"
                      fontWeight={'500'}
                      fontSize={'16px'}
                      borderRadius={'21px'}
                      onClick={() => {
                        setId(item._id);
                        onOpen();
                      }}
                    >
                      Cancel
                    </Button>
                  </HStack>
                )}
              {(selectedService === 'completed' ||
                selectedService === 'cancel') &&
                type === 'patient' && (
                  <HStack mt={8} mb={2}>
                    {selectedService !== 'cancel' && (
                      <Button
                        w="100%"
                        bgGradient="linear(to-r, #295377, #208C74)"
                        color="#E9E9E9"
                        fontWeight={'500'}
                        fontSize={'16px'}
                        borderRadius={'21px'}
                        border={'2px solid transparent'}
                        _hover={{
                          bgGradient: 'none',
                          borderColor: 'primaryGreen.200',
                          color: 'primaryGreen.200',
                        }}
                        as={Link}
                        to={`/dashboard/book-appointment/${item.doctorId}?type=rebook&appointmentId=${item._id}`}
                      >
                        Re-book
                      </Button>
                    )}

                    <Button
                      w="100%"
                      shadow={'0px 16px 38px 0px #0000000F'}
                      border={'1px solid #75767A'}
                      color="#75767A"
                      fontWeight={'500'}
                      fontSize={'16px'}
                      borderRadius={'21px'}
                      as={Link}
                      to={
                        selectedService === 'cancel'
                          ? `/dashboard/book-appointment/${item.doctorId}?type=rebook&appointmentId=${item._id}`
                          : ''
                      }
                      onClick={() => {
                        if (
                          selectedService === 'completed' &&
                          type === 'patient'
                        )
                          setId(item._id);
                        onOpenAddReview();
                      }}
                    >
                      {selectedService === 'completed'
                        ? 'Add Review'
                        : 'Re-book'}
                    </Button>
                  </HStack>
                )}
              {/* Patient */}
              {(selectedService === 'pending' ||
                selectedService === 'ongoing') &&
                type !== 'patient' && (
                  <HStack mt={8} mb={2}>
                    <Button
                      w="100%"
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'16px'}
                      borderRadius={'21px'}
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                      isLoading={loading}
                      onClick={
                        selectedService === 'pending'
                          ? () => handleAccept(item._id, 'accepted')
                          : selectedService === 'ongoing'
                          ? () =>
                              navigate(
                                `/dashboard/appointments/appointment-details/${item._id}`
                              )
                          : undefined
                      }
                    >
                      {selectedService === 'pending'
                        ? 'Accept'
                        : 'View Details'}
                    </Button>
                    {type !== 'practitioner' && (
                      <Button
                        w="100%"
                        shadow={'0px 16px 38px 0px #0000000F'}
                        border={'1px solid #75767A'}
                        color="#75767A"
                        fontWeight={'500'}
                        fontSize={'16px'}
                        borderRadius={'21px'}
                        onClick={() => {
                          setId(item._id);
                          onOpen();
                        }}
                      >
                        {selectedService === 'pending'
                          ? 'Cancel'
                          : 'Re-schedule'}
                      </Button>
                    )}
                  </HStack>
                )}
              {(selectedService === 'cancel' ||
                selectedService === 'completed') &&
                type !== 'patient' && (
                  <>
                    <Stack my={4}>
                      <Divider mt={4} />
                    </Stack>
                    <Stack
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <HStack marginTop={'2px'}>
                        <Avatar
                          size="sm"
                          name="Kent Dodds"
                          src={
                            `${imgUrl}/${item.assignedDoctorPhoto}` ||
                            'https://bit.ly/kent-c-dodds'
                          }
                        />{' '}
                        <Text
                          lineHeight={'5px'}
                          fontSize={'18px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {item.assignedDoctor || 'Adam Knight'}
                        </Text>
                      </HStack>
                      <Button
                        as={Link}
                        to={`/dashboard/doctor-profile/${item.doctorId}`}
                        bgGradient="linear(to-r, #295377, #208C74)"
                        color="#E9E9E9"
                        border={'2px solid transparent'}
                        _hover={{
                          bgGradient: 'none',
                          borderColor: 'primaryGreen.200',
                          color: 'primaryGreen.200',
                        }}
                        fontWeight={'500'}
                        fontSize={'16px'}
                        borderRadius={'21px'}
                      >
                        {type === 'practitioner'
                          ? 'My Profile'
                          : 'View Profile'}
                      </Button>
                    </Stack>
                    <Stack
                      mt={4}
                      mb={2}
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Stack margin={'0 !important'}>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          Appointment Date
                        </Text>
                        <Text
                          lineHeight={'10px'}
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {moment(item.endTime).format('M/D/YYYY') ||
                            '2/27/2024'}
                        </Text>
                      </Stack>
                      <Stack margin={'0 !important'}>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          Patient Type
                        </Text>
                        <Text
                          lineHeight={'10px'}
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {item.type || 'Consultation'}
                        </Text>
                      </Stack>
                      <Stack margin={'0 !important'}>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          Time
                        </Text>
                        <Text
                          lineHeight={'10px'}
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {moment(item.startTime).format('h - mm A') ||
                            ' 3 - 30 PM'}
                        </Text>
                      </Stack>
                      <Stack margin={'0 !important'}>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          SAR Price
                        </Text>
                        <Text
                          lineHeight={'10px'}
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          SAR {item.assignedDoctorCharges || '120.00'}
                        </Text>
                      </Stack>
                    </Stack>
                  </>
                )}
            </Box>
          </>
        ))
      ) : (
        <Heading>No Appointments</Heading>
      )}
    </Grid>
  );
}

export default React.memo(AppointmentBox);
