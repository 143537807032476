import React, { useState } from 'react';
import MainDashboard from '../../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../../components/Header/DashboardHeader';
import Modal from '../../../../components/Dashboard/Modal';
import { AttachmentIcon } from '@chakra-ui/icons';

function Electronic() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };

  const handleFileChange2 = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick2 = () => {
    document.getElementById('file-input').click();
  };

  return (
    <Stack overflowY="hidden">
      <MainDashboard>
        <DashboardHeader />
        <Stack
          overflowY="auto"
          margin={'0 !important'}
          gap={3}
          borderRadius={'30px 0 0 0'}
          py={10}
          px={6}
          bg={'#FBFBFB'}
          height={'100%'}
          bgPos={'-32px -100px'}
        >
          <Stack>
            <Heading fontSize={'36px'} fontWeight={'500'}>
              Electronic Advertising
            </Heading>
            <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
              Fill out the form...!
            </Text>
          </Stack>
          <Grid templateColumns="repeat(2, 1fr)" gap={3}>
            <Box>
              <form className="applyForm">
                <Box>
                  <FormLabel>Hospital Website</FormLabel>
                  <Input type="text" placeholder="Enter here" />
                </Box>
                <Box>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" placeholder="Enter here" />
                </Box>
                <Button
                  w="50%"
                  mt={'25px'}
                  bgGradient="linear(to-r, #295377, #208C74)"
                  color="#E9E9E9"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  borderRadius={'21px'}
                  onClick={onOpen}
                >
                  <Stack
                    w="100%"
                    direction={'row'}
                    alignItems={'center'}
                    px={4}
                    justifyContent={'space-between'}
                  >
                    <Text fontSize={'16px'} fontWeight={'500'}>
                      SAR 80.00
                    </Text>
                    <Text fontSize={'16px'} fontWeight={'500'}>
                      Continue
                    </Text>
                  </Stack>
                </Button>
              </form>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Box w="48%">
                <Box w="100%">
                  <FormLabel>Banner For Website</FormLabel>
                  <Input
                    name="document"
                    type="file"
                    id="file-input"
                    required
                    display="none"
                    onChange={handleFileChange}
                  />
                  <Text
                    fontSize="14px"
                    color="primaryGray.100"
                    textAlign={'right'}
                  >
                    <Button
                      cursor={'pointer'}
                      as="span"
                      border="1px solid #75767A"
                      mb="10px"
                      bgColor="transparent"
                      fontSize="14px"
                      w="100%"
                      color="primaryGray.100"
                      transition=".3s"
                      _hover={{
                        bgColor: 'primaryGreen.200',
                        color: '#fff',
                        borderColor: 'primaryGreen.200',
                      }}
                      mr="10px"
                      onClick={handleButtonClick}
                    >
                      <AttachmentIcon mr="10px" /> Attach File
                    </Button>
                    {selectedFile ? selectedFile.name : 'No File chosen'}
                  </Text>
                </Box>
                <Box w="100%">
                  <FormLabel>Instagram Ad</FormLabel>
                  <Input
                    name="document"
                    type="file"
                    id="file-input"
                    required
                    display="none"
                    onChange={handleFileChange2}
                  />
                  <Text
                    fontSize="14px"
                    color="primaryGray.100"
                    textAlign={'right'}
                  >
                    <Button
                      cursor={'pointer'}
                      as="span"
                      border="1px solid #75767A"
                      bgColor="transparent"
                      fontSize="14px"
                      color="primaryGray.100"
                      w="100%"
                      mb="10px"
                      transition=".3s"
                      _hover={{
                        bgColor: 'primaryGreen.200',
                        color: '#fff',
                        borderColor: 'primaryGreen.200',
                      }}
                      mr="10px"
                      onClick={handleButtonClick2}
                    >
                      <AttachmentIcon mr="10px" /> Attach File
                    </Button>
                    {selectedFile ? selectedFile.name : 'No File chosen'}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Stack>
      </MainDashboard>
    </Stack>
  );
}

export default Electronic;
