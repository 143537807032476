import React, { useState } from 'react';
import MainDashboard from '../../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
  useToast,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../../components/Header/DashboardHeader';
import Modal from '../../../../components/Dashboard/Modal';
import { POST } from '../../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

function PaymentDetails() {
  return (
    <form className="applyForm">
      <Box>
        <FormLabel>Enter Card Number</FormLabel>
        <Input type="text" placeholder="Enter here" />
      </Box>
      <Box>
        <FormLabel>Enter Card Holder Name</FormLabel>
        <Input type="text" placeholder="Enter here" />
      </Box>
      <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
        <Box w="48%">
          <FormLabel>Expiry Date</FormLabel>
          <Input type="date" placeholder="Enter here" />
        </Box>
        <Box w="48%">
          <FormLabel>CVC</FormLabel>
          <Input type="text" placeholder="Enter here" />
        </Box>
      </Stack>
      <Box>
        <Checkbox colorScheme="teal" fontSize={'15px'} fontWeight={400}>
          Save this card for future payment?{' '}
        </Checkbox>
      </Box>
      <Box w="100%">
        <Button
          w="100%"
          mt={'15px'}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
        >
          Continue & Pay
        </Button>
      </Box>
    </form>
  );
}
function Training() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(state => state.user.value);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData(e.target);
      console.log(user?.hospital);
      formData.append('doctor', user?.hospital);
      const response = await POST('/hospital/service', formData);
      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Form Submitted SucessFully!!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        e.target.reset();
      } else {
        setLoading(false);
        toast({
          description: response.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Stack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={'Payment Details'}
        subTitle={'Enter the payment details'}
        children={<PaymentDetails />}
      />
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Training Hub
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          If an query you can send it to the platform admin...!
        </Text>
      </Stack>
      <form onSubmit={handleSubmit} className="applyForm">
        <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="50%">
            <FormLabel>Choose Item</FormLabel>
            <Select placeholder="Choose Item">
              <option value="digitalmedicallibrary">
                Digital Medical Library
              </option>
              <option value="traininghub">Training Hub</option>
            </Select>
          </Box>
          <Box w="50%">
            <FormLabel>Email Address</FormLabel>
            <Input name="email" placeholder="Bachelor of Surgery (MBBS)" />
          </Box>
        </Stack>
        <Box>
          <FormLabel>Describe your issue in detail</FormLabel>
          <Textarea
            h="120px !important"
            size={'lg'}
            name="description"
            resize={'none'}
            placeholder="Enter here"
          />
        </Box>
        <Button
          w="30%"
          mt={'15px'}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          type="submit"
          isLoading={loading}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          // onClick={onOpen}
        >
          <Stack
            w="100%"
            direction={'row'}
            alignItems={'center'}
            px={4}
            justifyContent={'space-between'}
          >
            <Text fontSize={'16px'} fontWeight={'500'}>
              SAR 80.00
            </Text>
            <Text fontSize={'16px'} fontWeight={'500'}>
              Continue
            </Text>
          </Stack>
        </Button>
      </form>
    </Stack>
  );
}

export default Training;
