import React, { useEffect, useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Divider,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  Image,
  VStack,
  FormLabel,
  Select,
  Textarea,
  Checkbox,
  Input,
  useDisclosure,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { CalendarIcon, EditIcon, DownloadIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import Doctor from '../../../assets/images/dashboard/doctor.png';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AppointmentBox from './AppointmentBox';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GET, POST, PUT } from '../../../utilities/ApiProvider';
import moment from 'moment';
import { imgUrl } from '../../../utilities/config';
import ModalWrapper from '../../../components/Dashboard/Modal';
import { useSelector } from 'react-redux';
import Report from '../../../assets/images/dashboard/report.png';
function CancelAppointment({ id, onClose2 }) {
  const toast = useToast();
  const navigate = useNavigate();
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const form = new FormData(e.target);
      form.append('status', 'cancelled');
      const response = await PUT(`/hospital/appointments/${id}/cancel`, form);
      if (response.status === 200) {
        navigate('/dashboard/appointments');
        toast({
          title: 'Appointment Cancelled',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose2();
      }
    } catch (error) {
      console.log('Error while canceling', error);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="applyForm">
        <Box mb="10px">
          <FormLabel>Choose an option</FormLabel>
          <Select name="reason">
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
        </Box>
        <Box mb="20px !important">
          <FormLabel>Describe your issue</FormLabel>
          <Textarea
            name="issue"
            // border="1px solid #75767A"
            // borderRadius="12px"
            resize={'none'}
            placeholder="Here is a sample placeholder"
          />
        </Box>
        <Box>
          <Button
            w="100%"
            mt="10px"
            bgGradient="linear(to-r, #295377, #208C74)"
            color="#E9E9E9"
            fontWeight={'500'}
            fontSize={'16px'}
            borderRadius={'21px'}
            type="submit"
          >
            Send
          </Button>
        </Box>
      </form>
    </>
  );
}

function RescheduleAppointment({ id, onClose }) {
  const [openingHours, setOpeningHours] = useState([
    {
      on: true,
      day: 'Monday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Tuesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Wednesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Thursday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Friday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Saturday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Sunday',
      startTime: '09:00',
      endTime: '22:00',
    },
  ]);
  const [selectedDayIndex, setSelectedDayIndex] = useState(null);
  const [reason, setReason] = useState('');
  const toast = useToast();
  const navigate = useNavigate();
  const handleDaySelection = index => {
    setSelectedDayIndex(index);
  };

  const handleRequestSend = async () => {
    const selectedDay = openingHours[selectedDayIndex];
    try {
      const response = await POST(`/hospital/appointments/${id}/reschedule`, {
        reasons: reason,
        availability: [selectedDay],
        isReschedule: true,
        status: 'pending',
      });
      if (response.status === 200) {
        navigate('/dashboard/appointments');

        toast({
          title: 'Rescheduled successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      }
    } catch (error) {
      console.log('Erorr while rescheduling', error);
    }
  };
  return (
    <>
      <form className="applyForm">
        <Box mb="15px !important">
          <FormLabel>Reason of reschedule</FormLabel>
          <Textarea
            onChange={e => setReason(e.target.value)}
            resize={'none'}
            placeholder="Here is a sample placeholder"
          />
        </Box>
        <Box mb="10px">
          <FormLabel>Choose Availability</FormLabel>
          {openingHours.map((val, index) => (
            <Stack
              border={'2px solid'}
              borderRadius={'12px'}
              borderColor={!val.on ? '#75767A' : 'primaryGreen.200'}
              p="5px 5px 5px 15px"
              key={val.day}
              direction={'row'}
              alignItems={'center'}
              mb="0px !important"
            >
              <Text flex={'1'}>
                <Checkbox
                  colorScheme="teal"
                  defaultChecked={val?.on}
                  isChecked={selectedDayIndex === index}
                  onChange={() => handleDaySelection(index)}
                  // onChange={e => changeOnStatus(ind, e.target.checked)}
                  mr="5px"
                  mt="3px"
                  transform="translateY(2px)"
                ></Checkbox>
                {val.day}:
              </Text>
              <Text
                bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                borderRadius={'12px'}
                color={'#fff'}
              >
                <Input
                  isDisabled={!val.on}
                  height={'42px !important'}
                  borderColor={'primaryGreen.200 !important'}
                  // onChange={e => changeStartingTime(ind, e.target.value)}
                  type="time"
                  defaultValue={val.startTime}
                  value={val.startTime}
                />
              </Text>
              <Text
                bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                borderRadius={'12px'}
                color={'#fff'}
              >
                <Input
                  isDisabled={!val.on}
                  height={'42px !important'}
                  borderColor={'primaryGreen.200 !important'}
                  // onChange={e => changeEndingTime(ind, e.target.value)}
                  type="time"
                  defaultValue={val.endTime}
                  value={val.endTime}
                />
              </Text>
            </Stack>
          ))}
        </Box>
        <Box mb="15px !important">
          <Button
            w="100%"
            onClick={handleRequestSend}
            mt={'10px'}
            bgGradient="linear(to-r, #295377, #208C74)"
            color="#E9E9E9"
            fontWeight={'500'}
            fontSize={'16px'}
            borderRadius={'21px'}
          >
            Send Request
          </Button>
        </Box>
      </form>
    </>
  );
}
function AppointmentDetail() {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const user = useSelector(state => state.user.value);
  const [type, setType] = useState(user?.type);
  const [appointment, setAppointment] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  async function fetchAppointmentDetail() {
    try {
      const response = await GET(`/hospital/appointments/${id}`);
      console.log(response.data);
      if (response.status === 200) setAppointment(response.data);
    } catch (error) {
      console.log('Error while fetching appointments details', error);
    }
  }
  useEffect(() => {
    if (user) {
      setType(user.type);
      // setType('superadmin');
    }
  }, [user]);
  useEffect(() => {
    fetchAppointmentDetail();
  }, []);

  const handleCompleted = async id => {
    setLoading(false);
    try {
      if (!id) return;
      const response = await POST(`/hospital/appointments/${id}/action`, {
        status: 'completed',
      });
      if (response.status === 200) {
        navigate('/dashboard/appointments');
        toast({
          description: `Appointment completed successfully`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          Appointment Details /{' '}
          <Text
            mt={'10px'}
            ml={'8px'}
            color={'#208C74'}
            fontSize={'20px'}
            fontWeight={'500'}
          >
            Appointment
          </Text>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all patient list...Check Now!
        </Text>
      </Stack>
      {/*Heading  */}
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Re-schedule Appointment'}
        subTitle={'Enter the details to add new service'}
        children={<RescheduleAppointment id={id} onClose={onClose} />}
      />
      <ModalWrapper
        isOpen={isOpen2}
        onClose={onClose2}
        title={'Cancel Appointment'}
        subTitle={'Do you want to “Cancel” this appointment?'}
        children={<CancelAppointment id={id} onClose2={onClose2} />}
      />
      {!appointment ? (
        <Stack w="100%" h="100vh">
          <Spinner />
        </Stack>
      ) : (
        <Grid gridTemplateColumns={'2fr 1fr'} gap={6}>
          <Box display={'flex'} flexDirection={'column'} gap={5}>
            <Stack>
              <Avatar
                w="151px"
                h="151px"
                name="Prosper Otemuyiwa"
                src={
                  appointment?.doctor?.photo !== ''
                    ? `${imgUrl}/${appointment?.doctor?.photo}`
                    : 'https://bit.ly/dan-abramov'
                }
              />

              <Stack
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                pr={5}
                flexWrap={'wrap'}
              >
                <Stack>
                  <Heading
                    display={'flex'}
                    alignItems={'center'}
                    fontSize={'26px'}
                    fontWeight={'500'}
                  >
                    Dr. {appointment?.doctor?.name || 'Dr. Alexa John'} /{' '}
                    <Text
                      mt={'8px'}
                      ml={'2px'}
                      color={'#208C74'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                    >
                      Male
                    </Text>
                  </Heading>
                  <Text fontSize={'20px'} color={'#75767A'} fontWeight={'400'}>
                    #d44vdsQ
                  </Text>
                </Stack>
                {type === 'superadmin' && (
                  <Stack>
                    <Button
                      w="100%"
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'15px'}
                      borderRadius={'21px'}
                    >
                      View Doctor Profile
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack>
              <Heading fontSize={'20px'} fontWeight={'500'}>
                Note
              </Heading>
              <Text fontSize={'16px'} color={'#75767A'} fontWeight={'400'}>
                {appointment?.notes ||
                  ` Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.`}
              </Text>
            </Stack>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              pr={5}
              flexWrap={'wrap'}
            >
              <Stack margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Appointment Date
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {moment(appointment.startTime).format('M/D/YYYY') ||
                    '2/27/2024'}
                </Text>
              </Stack>
              <Stack margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Patient Type:
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {appointment?.type || 'Virtual Consultation'}
                </Text>
              </Stack>
              <Stack margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  SAR Price:
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  SAR {appointment?.doctor?.charges || '120.00'}
                </Text>
              </Stack>
            </Stack>
            <Stack>
              <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                Time:
              </Text>
              <Text
                lineHeight={'10px'}
                fontSize={'16px'}
                fontWeight={'500'}
                color={'#1B1C1D'}
              >
                {moment(appointment.startTime).format('h - mm A') ||
                  ' 3 - 30 PM'}
              </Text>
            </Stack>
            {type !== 'practitioner' && type !== 'superadmin' && (
              <HStack mt={'15px'} width={'90%'}>
                <Button
                  w="50%"
                  bgGradient="linear(to-r, #295377, #208C74)"
                  color="#E9E9E9"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  borderRadius={'21px'}
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                  onClick={onOpen}
                >
                  Re-schedule Appointment
                </Button>
                <Button
                  w="50%"
                  shadow={'0px 16px 38px 0px #0000000F'}
                  border={'1px solid #75767A'}
                  color="#75767A"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  borderRadius={'21px'}
                  onClick={onOpen2}
                >
                  Cancel Appointment
                </Button>
              </HStack>
            )}
          </Box>
          {type !== 'patient' && (
            <Box
              shadow={'lg'}
              w="100%"
              borderRadius={'16px'}
              bg={'#fff'}
              p={5}
              height={type === 'practitioner' ? '245px' : '300px'}
            >
              <HStack justifyContent={'space-between'}>
                <HStack alignItems={'center'}>
                  <WrapItem>
                    <Avatar
                      size="lg"
                      name="Prosper Otemuyiwa"
                      src={
                        `${imgUrl}/${appointment?.patient?.picture}` ||
                        'https://bit.ly/prosper-baba'
                      }
                    />{' '}
                  </WrapItem>
                  <Stack justifyContent={'center'}>
                    <Heading fontWeight={'400'} fontSize={'22px'}>
                      {appointment?.patient?.fullName || 'Adam Knight'}
                    </Heading>
                    <Text
                      lineHeight={0}
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      #{appointment?.patient?.platformId}
                    </Text>
                  </Stack>
                </HStack>
              </HStack>
              <Stack gap={4} mt={4}>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Appointment Date
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {moment(appointment.startTime).format('M/D/YYYY') ||
                        '2/27/2024'}
                    </Text>
                  </Stack>

                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Patient Type
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {appointment?.type || 'Consultation'}
                    </Text>
                  </Stack>
                </Stack>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      SAR Price
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      SAR {appointment?.doctor?.charges || '120.00'}
                    </Text>
                  </Stack>

                  <Stack flex={1} margin={'0 !important'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'400'}
                      color={'#75767A'}
                    >
                      Time
                    </Text>
                    <Text
                      lineHeight={'10px'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      color={'#1B1C1D'}
                    >
                      {moment(appointment.startTime).format('h - mm A') ||
                        ' 3 - 30 PM'}
                    </Text>
                  </Stack>
                </Stack>
                {type !== 'practitioner' && (
                  <Stack>
                    <Button
                      w="100%"
                      as={Link}
                      to={`/dashboard/patient-profile/${appointment?.patient?._id}`}
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'15px'}
                      borderRadius={'21px'}
                    >
                      View Patient Profile
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Box>
          )}
        </Grid>
      )}
      {type === 'hospital' || type === 'practitioner' ? (
        <>
          <Divider mt={'25px !important'} color={'#75767A'} />
          <Text fontSize={'18px'} fontFamily={'400'}>
            If the service is completed, please mark this “Service Completed”.
          </Text>
          <Stack mt={type === 'practitioner' ? '1.5rem !important' : '0'}>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              gap={6}
              alignItems={'center'}
            >
              <Stack flex={1}>
                <Button
                  w="60%"
                  bgGradient="linear(to-r, #295377, #208C74)"
                  color="#E9E9E9"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  isLoading={loading}
                  borderRadius={'21px'}
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                  onClick={() => handleCompleted(appointment?._id)}
                >
                  Service Completed
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </>
      ) : null}
      {type === 'superadmin' && (
        <Stack pt={4}>
          <Text fontSize={'20px'} fontWeight={'500'}>
            Patient Report
          </Text>

          <Stack
            display={'flex'}
            flexDirection={'row'}
            gap={4}
            alignItems={'center'}
          >
            <Stack
              pt={4}
              w="272px"
              h="200px"
              bg={'#D9D9D9'}
              px={2.5}
              borderRadius={'12px'}
            >
              <Stack
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                w="250px"
                h="148px"
                bg={'#FBFBFB'}
                borderRadius={'12px'}
              >
                <Image w="66px" h="86px" src={Report} alt="report" />
              </Stack>
              <Text fontSize={'16px'} py={2} px={2} fontWeight={'500'}>
                Here is my report
              </Text>
            </Stack>
            <Stack>
              <Box
                cursor={'pointer'}
                w="32px"
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                h={'32px'}
                bg={'#D9D9D9'}
                borderRadius={'full'}
              >
                <DownloadIcon />
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default AppointmentDetail;
