import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Divider,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  Image,
  VStack,
  Icon,
  Spinner,
  Input,
  FormLabel,
  useDisclosure,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import ModalWrapper from '../../../components/Dashboard/Modal';
import { POST } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { imgUrl } from '../../../utilities/config';
import { TiDelete } from 'react-icons/ti';

function AddSocialLink({ socialLinks, setSocialLinks, onClose }) {
  const [selectedImage, setSelectedImage] = useState('');
  const handleImageChange = event => {
    console.log(event.target.files);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        console.log(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    const form = new FormData(e.target);
    let data = {};
    data['icon'] = selectedImage;
    data['id'] = socialLinks.length + 1;
    form.forEach((value, key) => {
      console.log(key, value);
      data[key] = value;
    });
    console.log(data);
    setSocialLinks([...socialLinks, data]);
    onClose();
  };
  return (
    <form className="applyForm" onSubmit={handleSubmit}>
      <Box mb={4}>
        <FormLabel>Name*</FormLabel>
        <Box position={'relative'}>
          <Input
            name="name"
            // name={val?.name?.toLowerCase()}
            type="text"
            placeholder="Enter name"
          />
        </Box>
      </Box>
      <Box mb={4}>
        <FormLabel>Value*</FormLabel>
        <Box position={'relative'}>
          <Input
            name="url"
            // name={val?.name?.toLowerCase()}
            type="text"
            placeholder="Enter name"
          />
        </Box>
      </Box>
      <Box mb={'20px !important'}>
        <FormLabel>Icon</FormLabel>
        <Input
          id="iconUploadss"
          onChange={handleImageChange}
          display="none"
          type="file"
        />
        <Button
          w="100%"
          h="50px"
          border={'1px solid #1B1C1D'}
          borderRadius={'10px'}
          onClick={() => document.getElementById('iconUploadss').click()}
        >
          Change
        </Button>
      </Box>
      <Button
        bgGradient={'linear(to-r, #295377, #208C74)'}
        fontSize={'16px'}
        fontWeight={500}
        color={'#fff'}
        w="100%"
        // isLoading={isLoading}
        borderRadius={'25px'}
        h="45px"
        px="30px"
        border={'2px solid transparent'}
        _hover={{
          bgGradient: 'none',
          borderColor: 'primaryGreen.200',
          color: 'primaryGreen.200',
        }}
        type="submit"
      >
        Add link
      </Button>
    </form>
  );
}

function ElectronicAdvertising() {
  const [selectedImage, setSelectedImage] = useState();
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState([]);
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const [socialLinks, setSocialLinks] = useState([]);
  const { seo } = useSelector(state => state.website.data);
  console.log(seo);
  useEffect(() => {
    setSelectedImage(`${imgUrl}/${seo?.logo}`);
    setSocialLinks(seo?.socials);
    setBanner(seo?.banners);
  }, [seo]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleImageChange = event => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        console.log(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDelete = async id => {
    let modifiedArray = socialLinks.filter(item => item.id !== id);
    setSocialLinks(modifiedArray);
  };
  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      const form = new FormData(e.target);
      form.append('logos', logo);
      form.append('socials', JSON.stringify(socialLinks));
      form.append('banners', JSON.stringify(banner));
      console.log(banner);
      const response = await POST('/admin/seo', form);
      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Data saved successfully',
          status: 'success',
          duration: 2000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        setLoading(false);
        toast({
          description: response.data.message,
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }

    // let data = {};
    // data['logos'] = logo;
    // data['socials'] = JSON.stringify(socialLinks);
    // data['banners'] = banner;
    // form.forEach((value, key) => {
    //   console.log(key, value);
    //   data[key] = value;
    // });
    // console.log(data);
    // setSocialLinks([...socialLinks, data]);
    // onClose();
  };
  const handleBannerChange = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBanner(prevState => [...prevState, { url: reader.result }]);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteBanner = async id => {
    let modifiedArray = banner.filter(item => item._id !== id);
    setBanner(modifiedArray);
  };
  const handleCheckbox = (e, id) => {
    if (e.target.checked) {
      let modifiedArray = socialLinks.map(item =>
        item._id === id ? { ...item, isActive: true } : item
      );
      setSocialLinks(modifiedArray);
    } else {
      let modifiedArray = socialLinks.map(item =>
        item._id === id ? { ...item, isActive: false } : item
      );
      setSocialLinks(modifiedArray);
    }
  };
  const handleNameAndUrl = (e, id, type) => {
    let modifiedArray = socialLinks.map(item =>
      item._id === id ? { ...item, [type]: e.target.value } : item
    );
    setSocialLinks(modifiedArray);
  };
  return (
    <Stack>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Add Social Link'}
        subTitle={'Enter the details to add new service'}
        children={
          <AddSocialLink
            socialLinks={socialLinks}
            setSocialLinks={setSocialLinks}
            onClose={onClose}
          />
        }
      />
      {/*Heading  */}
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          Top Header Settings
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all your top header info...Check Now!
        </Text>
      </Stack>
      {/*Heading  */}

      <Stack>
        <Text fontSize={'20px'} fontWeight={500} color={'#1B1C1D'}>
          Logo
        </Text>
        <Stack direction={'row'} gap={4} alignItems={'center'}>
          <Box
            w="221px"
            p={1}
            h="96px"
            borderRadius={'14px'}
            border={'1px solid '}
          >
            <Image
              borderRadius={'14px'}
              w="100%"
              h="100%"
              src={
                selectedImage ||
                'http://localhost:3000/static/media/eMedst%20Logo.fb143bc10c5837e3a8673145178ab9f5.svg'
              }
            />
          </Box>
          <Input
            type="file"
            accept=".jpg,.jpeg,.png,.gif,.svg"
            onChange={handleImageChange}
            display="none"
            id="imageUpload"
          />

          <Button
            w="114px"
            h="40px"
            border={'1px solid #1B1C1D'}
            borderRadius={'21.5px'}
            onClick={() => document.getElementById('imageUpload').click()}
          >
            Change
          </Button>
        </Stack>
      </Stack>
      <Stack>
        <form onSubmit={handleSubmit} className="applyForm">
          <Stack
            w="100%"
            flexDirection={'row'}
            alignItems={'center'}
            gap={4}
            flexWrap={'wrap'}
          >
            <Box w="48%">
              <FormLabel>Phone No.</FormLabel>
              <Stack>
                <Input
                  required
                  name="number"
                  defaultValue={seo?.number}
                  type="text"
                  placeholder="Enter Phone No."
                />
                {/* <Box
                  w="34px"
                  h="34px"
                  borderRadius={'21.5px'}
                  pos={'absolute'}
                  top="2px"
                  right="20px"
                  border={'1px solid #1B1C1D'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Icon
                    as={RiDeleteBin6Line}
                    color={'#1B1C1D'}
                    w="20px"
                    h="20px"
                  />
                </Box> */}
              </Stack>
            </Box>
            <Box w="48%">
              <FormLabel>Email Address</FormLabel>
              <Stack>
                <Input
                  required
                  name="email"
                  type="email"
                  defaultValue={seo?.email}
                  placeholder="Enter Email Address"
                />
                {/* <Box
                  w="34px"
                  h="34px"
                  borderRadius={'21.5px'}
                  pos={'absolute'}
                  top="2px"
                  right="20px"
                  border={'1px solid #1B1C1D'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Icon
                    as={RiDeleteBin6Line}
                    color={'#1B1C1D'}
                    w="20px"
                    h="20px"
                  />
                </Box> */}
              </Stack>
            </Box>
          </Stack>
          <Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Text fontSize={'20px'} fontWeight={500} color={'#1B1C1D'}>
                Social Links
              </Text>
              {/* <Button
                mr={'20px !important'}
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={{ base: '12px', xl: '14px' }}
                fontWeight={500}
                color={'#fff'}
                onClick={onOpen}
                borderRadius={'25px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                Add New
              </Button> */}
            </Stack>
            {socialLinks?.map((val, ind) => (
              <Stack
                w="100%"
                flexDirection={'row'}
                alignItems={'center'}
                gap={4}
                flexWrap={'wrap'}
              >
                <Box w="28.33%">
                  <FormLabel>Name</FormLabel>
                  <Input
                    required
                    onChange={e => handleNameAndUrl(e, val?._id, 'name')}
                    defaultValue={val?.name}
                    type="text"
                    placeholder="Enter Facebook Link"
                  />
                </Box>
                <Box w="28.33%" mt={'0 !important'}>
                  <FormLabel>Url</FormLabel>
                  <Input
                    required
                    onChange={e => handleNameAndUrl(e, val?._id, 'url')}
                    type="text"
                    defaultValue={val?.url}
                    placeholder="Enter Url"
                  />
                </Box>
                <Box w="28.33%" mt={'0 !important'}>
                  <FormLabel>Show</FormLabel>
                  <Box
                    w="36px"
                    h="36px"
                    borderRadius="50%"
                    border="1px solid #1B1C1D"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Checkbox
                      onChange={e => handleCheckbox(e, val?._id)}
                      size="lg"
                      colorScheme="teal"
                      defaultChecked={val?.isActive}
                      // defaultChecked={val?.icon ? true : false}
                    />
                    {/* <Image
                      borderRadius="50%"
                      w="100%"
                      h="100%"
                      src={
                        val?.icon?.includes('data:image')
                          ? val?.icon
                          : `${imgUrl}/${val?.icon}`
                      }
                    /> */}
                  </Box>
                  {/* <Input
                    required
                    id="iconUpload"
                    display="none"
                    name="twitter"
                    type="file"
                    placeholder="Enter Twitter Link"
                  /> */}
                  {/* <Button
                    w="100%"
                    h="50px"
                    border={'1px solid #1B1C1D'}
                    borderRadius={'10px'}
                    onClick={() =>
                      document.getElementById('iconUpload').click()
                    }
                  >
                    Change
                  </Button> */}
                </Box>
                {/* <Stack
                  direction={'row'}
                  alignItems={'end'}
                  mt={'30px !important'}
                >
                  <Box
                    w="34px"
                    h="34px"
                    cursor={'pointer'}
                    onClick={() => handleDelete(val?.id)}
                    borderRadius={'21.5px'}
                    border={'1px solid #1B1C1D'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Icon
                      as={RiDeleteBin6Line}
                      color={'#1B1C1D'}
                      w="20px"
                      h="20px"
                    />
                  </Box>
                </Stack> */}
              </Stack>
            ))}
          </Stack>
          <Stack>
            <Text fontSize={'20px'} fontWeight={500} color={'#1B1C1D'}>
              Upload Banner
            </Text>
            <Input
              onChange={handleBannerChange}
              padding={'10px !important'}
              type="file"
              multiple
              accept="image/*,application/pdf"
            />
          </Stack>
          <Stack flexDirection={'row'} gap={4}>
            {banner?.map((val, ind) => (
              <Box
                key={ind}
                width={'120px'}
                border={'1px solid #1B1C1D'}
                h={'120px'}
                p={2}
                pos={'relative'}
              >
                <Image
                  w="100%"
                  h="100%"
                  src={
                    val?.url?.includes('data:image')
                      ? val?.url
                      : `${imgUrl}${val?.url}`
                  }
                />
                <Icon
                  as={TiDelete}
                  w="40px"
                  h="40px"
                  pos="absolute"
                  top="-10px"
                  right="-20px"
                  cursor={'pointer'}
                  onClick={() => handleDeleteBanner(val?._id)}
                  //   onClick={() => handleDelete(val?.id)}
                />
              </Box>
            ))}
          </Stack>
          <Button
            type="submit"
            mt={'25px !important'}
            bgGradient={'linear(to-r, #295377, #208C74)'}
            fontSize={{ base: '12px', xl: '14px' }}
            fontWeight={500}
            w="150px"
            isLoading={loading}
            color={'#fff'}
            borderRadius={'25px'}
            h={{ base: '35px', xl: '45px' }}
            textTransform={'uppercase'}
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            Save
          </Button>
        </form>
      </Stack>
    </Stack>
  );
}

export default ElectronicAdvertising;
