import React, { useEffect, useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
  Spinner,
} from '@chakra-ui/react';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import moment from 'moment/moment';
import { GET } from '../../../utilities/ApiProvider';
import { Link } from 'react-router-dom';
function Wards() {
  const [wards, setWards] = useState(null);

  async function fetchWards() {
    try {
      const response = await GET('/hospital/ward');
      if (response.status === 200) setWards(response.data);
    } catch (error) {
      console.log('Error while fetching the wards', error);
    }
  }
  useEffect(() => {
    fetchWards();
  }, []);

  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Wards
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            If an query you can send it to the platform admin...!
          </Text>
        </Stack>
        <Stack>
          <Button
            as={Link}
            to={'/dashboard/add-wards'}
            w="100%"
            bgGradient="linear(to-r, #295377, #208C74)"
            color="#E9E9E9"
            fontWeight={'500'}
            fontSize={'15px'}
            borderRadius={'21px'}
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            Add New Ward
          </Button>
        </Stack>
      </HStack>

      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {!wards ? (
          <Spinner />
        ) : wards.length > 0 ? (
          wards?.map((item, ind) => (
            <Box
              shadow={'0px 16px 38px 0px #0000000F'}
              bg={'#fff'}
              borderRadius={'16px'}
              p={4}
              key={item?._id}
              display={'flex'}
              flexDirection={'column'}
              gap={4}
            >
              <HStack alignItems={'center'} justifyContent={'space-between'}>
                <Heading fontWeight={500} fontSize={'20px'}>
                  {item?.wardName}
                </Heading>
                <Text fontWeight={400} color="#75767A">
                  Founding Date:{' '}
                  {moment(item?.createdAt).format('MMMM D, YYYY')}
                </Text>
              </HStack>
              <Text fontWeight={400} color="#75767A">
                Ward Price:
              </Text>
              <Heading fontWeight={500} fontSize={'16px'}>
                SAR {item.price}
              </Heading>
              {/* <HStack>
                    <Button
                      w="50%"
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'15px'}
                      borderRadius={'21px'}
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                    >
                      Edit Ward
                    </Button>
                    <Button
                      w="50%"
                      shadow={'0px 16px 38px 0px #0000000F'}
                      border={'1px solid #75767A'}
                      color="#75767A"
                      fontWeight={'500'}
                      fontSize={'15px'}
                      borderRadius={'21px'}
                    >
                      Remove Ward
                    </Button>
                  </HStack> */}
            </Box>
          ))
        ) : (
          <Heading>No Ward Found </Heading>
        )}
      </Grid>
    </Stack>
  );
}

export default Wards;
