import React, { useState } from 'react';
import MainDashboard from '../../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../../components/Header/DashboardHeader';
import Modal from '../../../../components/Dashboard/Modal';
import ServiceCard from './ServiceCard';

function AddNewService() {
  const [openingHours, setOpeningHours] = useState([
    {
      on: true,
      day: 'Monday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Tuesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Wednesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Thursday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Friday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Saturday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Sunday',
      startTime: '09:00',
      endTime: '22:00',
    },
  ]);
  return (
    <form class="applyForm">
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        gap={2}
        margin={'0 important'}
      >
        <Box w="58%">
          <FormLabel>Service Name</FormLabel>
          <Input name="name" placeholder="Enter here" />
        </Box>
        <Box w="38%">
          <FormLabel>Service SAR Price</FormLabel>
          <Input name="name" placeholder="0.00" />
        </Box>
      </Stack>
      <Box>
        <FormLabel>Service Description</FormLabel>
        <Textarea resize={'none'} placeholder="Here is a sample placeholder" />
      </Box>
      <Box>
        <FormLabel>Choose Doctor</FormLabel>
        <Select placeholder="Enter here">
          <options>12</options>
          <options>12</options>
          <options>12</options>
        </Select>
      </Box>
      <Box mb="10px">
        <FormLabel>Choose Availability</FormLabel>
        {openingHours.map((val, ind) => (
          <Stack
            border={'2px solid'}
            borderRadius={'12px'}
            borderColor={!val.on ? '#75767A' : 'primaryGreen.200'}
            p="5px 5px 5px 15px"
            key={val.day}
            direction={'row'}
            alignItems={'center'}
            mb="0px !important"
          >
            <Text flex={'1'}>
              <Checkbox
                colorScheme="teal"
                defaultChecked={val?.on}
                // onChange={e => changeOnStatus(ind, e.target.checked)}
                mr="5px"
                mt="3px"
                transform="translateY(2px)"
              ></Checkbox>
              {val.day}:
            </Text>
            <Text
              bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
              borderRadius={'12px'}
              color={'#fff'}
            >
              <Input
                isDisabled={!val.on}
                height={'42px !important'}
                borderColor={'primaryGreen.200 !important'}
                // onChange={e => changeStartingTime(ind, e.target.value)}
                type="time"
                defaultValue={val.startTime}
                value={val.startTime}
              />
            </Text>
            <Text
              bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
              borderRadius={'12px'}
              color={'#fff'}
            >
              <Input
                isDisabled={!val.on}
                height={'42px !important'}
                borderColor={'primaryGreen.200 !important'}
                // onChange={e => changeEndingTime(ind, e.target.value)}
                type="time"
                defaultValue={val.endTime}
                value={val.endTime}
              />
            </Text>
          </Stack>
        ))}
      </Box>
      <Box>
        <Button
          w="100%"
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'16px'}
          borderRadius={'21px'}
        >
          Add
        </Button>
      </Box>
    </form>
  );
}
function Service() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = [
    {
      id: '1',
      title: 'Bed Booking',
      price: 'SAR 100.00',
      description:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      availability: [
        {
          day: 'Monday',
          time: '8:00 AM to 10:00 PM',
        },
        {
          day: 'Tuesday',
          time: 'Not Available',
        },
        {
          day: 'Wednesday',
          time: 'Not Available',
        },
        {
          day: 'Thursday',
          time: 'Not Available',
        },
        {
          day: 'Friday',
          time: '9:00 PM to 11:30 PM',
        },
        {
          day: 'Saturday',
          time: 'Not Available',
        },
        {
          day: 'Sunday',
          time: 'Not Available',
        },
      ],
    },
    {
      id: '2',
      title: 'Neurology',
      price: 'SAR 150.00',
      description:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      availability: [
        {
          day: 'Monday',
          time: '8:00 AM to 10:00 PM',
        },
        {
          day: 'Tuesday',
          time: '11:00 PM to 1:00 PM',
        },
        {
          day: 'Wednesday',
          time: 'Not Available',
        },
        {
          day: 'Thursday',
          time: '5:00 PM to 7:00 PM',
        },
        {
          day: 'Friday',
          time: 'Not Available',
        },
        {
          day: 'Saturday',
          time: 'Not Available',
        },
        {
          day: 'Sunday',
          time: 'Not Available',
        },
      ],
    },
    {
      id: '3',
      title: 'Orthopedic Consultation',
      price: 'SAR 150.00',
      description:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      availability: [
        {
          day: 'Monday',
          time: '8:00 AM to 10:00 PM',
        },
        {
          day: 'Tuesday',
          time: '11:00 PM to 1:00 PM',
        },
        {
          day: 'Wednesday',
          time: 'Not Available',
        },
        {
          day: 'Thursday',
          time: '5:00 PM to 7:00 PM',
        },
        {
          day: 'Friday',
          time: 'Not Available',
        },
        {
          day: 'Saturday',
          time: 'Not Available',
        },
        {
          day: 'Sunday',
          time: 'Not Available',
        },
      ],
    },
  ];

  return (
    <Stack overflowY="hidden">
      <MainDashboard>
        <DashboardHeader />
        <Stack
          overflowY="auto"
          margin={'0 !important'}
          gap={3}
          borderRadius={'30px 0 0 0'}
          py={10}
          px={6}
          bg={'#FBFBFB'}
          height={'100%'}
          bgPos={'-32px -100px'}
        >
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={'Add New Service'}
            subTitle={'Enter the details to add new service'}
            children={<AddNewService />}
          />
          {/*Heading  */}
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack>
              <Heading fontSize={'36px'} fontWeight={'500'}>
                Services
              </Heading>
              <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
                Here is the all services...Check Now!
              </Text>
            </Stack>
            <Button
              bgGradient="linear(to-r, #295377, #208C74)"
              color="#E9E9E9"
              fontWeight={'500'}
              fontSize={'15px'}
              borderRadius={'21px'}
              onClick={onOpen}
            >
              Add New Service
            </Button>
          </Stack>
          <Text fontSize={'20px'} fontWeight={'500'}>
            You've 7 services
          </Text>
          {/*Heading  */}
          <ServiceCard data={data} />
        </Stack>
      </MainDashboard>
    </Stack>
  );
}

export default Service;
