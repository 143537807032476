import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  VStack,
  Input,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import { useParams } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

function AdmissionRequest() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const user = useSelector(state => state.user.value);
  console.log(user);
  const toast = useToast();
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      form.append('service', id);
      form.append('hospital', user.hospital);
      // const formDataEntries = Array.from(form.entries());
      //   console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));
      const response = await POST('/hospital/service', form);
      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Form Submitted SucessFully!!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (error) {
      console.log('Erorr while submitting form ', error);
    } finally {
      setLoading(false);
      e.target.reset();
    }
  };

  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Request for Admission to a Medical Education & Training Program
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all services...Check Now!
        </Text>
        {/* <Text fontSize={'20px'} fontWeight={'500'}>
              You've 5 services
            </Text> */}
      </Stack>
      {/*Heading  */}
      <form onSubmit={handleSubmit} className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Hospital Information:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Name</FormLabel>
            <Input required name="name" type="text" placeholder="Enter Name" />
          </Box>
          <Box w="100%">
            <FormLabel>Address</FormLabel>
            <Input
              required
              name="address"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Email Address</FormLabel>
            <Input required name="email" type="text" placeholder="Enter Name" />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requesting Staff:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="staffName"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Mobile No.</FormLabel>
            <Input
              required
              name="staffNumber"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="staffEmail"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Requested Program:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Program Name</FormLabel>
            <Input
              required
              name="programName"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Duration</FormLabel>
            <Input
              required
              name="programDuration"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Location</FormLabel>
            <Input
              required
              name="programLocation"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

        <Button
          w="30.33%"
          mt={'25px'}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          type="submit"
          isLoading={loading}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          <Stack
            w="100%"
            direction={'row'}
            alignItems={'center'}
            px={4}
            justifyContent={'space-between'}
          >
            <Text fontSize={'16px'} fontWeight={'500'}>
              SAR 80.00
            </Text>
            <Text fontSize={'16px'} fontWeight={'500'}>
              Continue
            </Text>
          </Stack>
        </Button>
      </form>
    </Stack>
  );
}

export default AdmissionRequest;
