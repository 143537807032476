import React, { useEffect, useRef, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Checkbox,
  Select,
  useToast,
  Spinner,
  Image,
} from '@chakra-ui/react';
import { CalendarIcon, AttachmentIcon } from '@chakra-ui/icons';
import Check from '../../../../assets/images/dashboard/check.png';
import { GET, POST } from '../../../../utilities/ApiProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PatientBedBookingForm() {
  const { id } = useParams();

  const [departments, setDepartments] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [checkedItem, setCheckedItem] = useState('');
  const [typeOfBed, setBed] = useState('');
  const [beds, setBeds] = useState([]);
  const [payment, setPayment] = useState('');
  const [wardId, setWardId] = useState('');
  const [wards, setWards] = useState([]);
  const [selectedBedId, setSelectedBedId] = useState(null);
  const [selectCheckbox, setSelectCheckbox] = useState('');
  const [selectBed, setSelectBed] = useState('');
  const [selectTravel, setSelectTravel] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectPayment, setSelectPayment] = useState('');
  const user = useSelector(state => state.user.value);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    async function fetchDepartments() {
      try {
        const response = await GET(`/web/patient/department/${id}`);
        if (response.status === 200) setDepartments(response.data);
      } catch (error) {
        console.log('Error while fetching departments', error);
      }
    }

    fetchDepartments();
  }, []);
  const handleChange = id => {
    setSelectedId(id);
    const ward = departments.find(dep => dep._id === id)?.['ward'];
    if (ward) setWards(ward);
  };
  const handleCheckboxChange = event => {
    const { name, value } = event.target;
    setCheckedItem(name);
    setSelectCheckbox(value);
  };

  const handleBed = event => {
    const { name, value } = event.target;
    setBed(name);
    setSelectBed(value);
  };
  const handleTravel = event => {
    const { name, value } = event.target;
    setSelectTravel(value);
  };
  const handlePayment = event => {
    const { name, value } = event.target;
    setPayment(name);
    setSelectPayment(value);
  };
  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    // Create FormData object
    const form = new FormData(e.target);
    form.append('hospitalist', 'test');
    form.append('patient', user?._id);
    form.append('hospital', id);
    form.append('bed', selectedBedId);
    form.append('reports', selectedFile);

    // Validation checks
    if (form.get('department') === '' || form.get('ward') === '') {
      toast({
        description: 'Please select department and ward',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    if (!selectedBedId) {
      toast({
        description: 'Please select a bed',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    // Ensure a file is selected
    if (!selectedFile) {
      toast({
        description: 'Please upload a report',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    try {
      const form = new FormData(e.target);
      form.append('hospitalist', 'test');
      form.append('patient', user?._id);
      form.append('hospital', id);
      form.append('bed', selectedBedId);
      form.append('travel', selectTravel === 'Yes' ? true : false);

      if (form.get('department') === '' || form.get('ward') === '') {
        toast({
          description: 'Please select department and ward',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!selectedBedId) {
        toast({
          description: 'Please select a bed',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      const formDataEntries = Array.from(form.entries());
      console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));

      const response = await POST('/web/patient/department/book-a-bed', form);

      if (response.status === 200) {
        toast({
          description: 'Patient Bed Booked Successfully',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate('/dashboard');
      } else {
        toast({
          description:
            response.message || 'Something went wrong. Please try again.',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBeds = async () => {
    try {
      const response = await POST(`/web/patient/department`, {
        department: selectedId,
        ward: wardId,
      });
      console.log(response.data);
      if (response.status === 200) setBeds(response.data);
    } catch (error) {
      console.log('Error while fetching wards', error);
    }
  };
  useEffect(() => {
    if (selectedId && wardId) {
      fetchBeds();
    }
  }, [selectedId, wardId]);

  const handleCheckboxBedChange = bedId => {
    setSelectedBedId(bedId);
    console.log('Selected Bed ID:', bedId);
  };

  useEffect(() => {
    const handleWheel = e => {
      e.preventDefault(); // Prevent the default scroll behavior
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);
  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Fill Form
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>
      <form onSubmit={handleSubmit} className="applyForm" id="registerForm">
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Box
            w="50%"
            display={'flex'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            <Box w="100%" mb="10px">
              <FormLabel>Name*</FormLabel>
              <Input required name="name" placeholder="Enter here" />
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Age</FormLabel>
              <Input
                required
                type="number"
                name="age"
                placeholder="Enter here"
                ref={inputRef} // Attach ref to the Input
              />
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Gender</FormLabel>
              <Select required name="gender" placeholder="Choose option">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Select>
            </Box>
            <Stack
              w="100%"
              direction={'row'}
              alignItems={'center'}
              mb="10px"
              gap={4}
            >
              <Box w="100%">
                <FormLabel>Start Date</FormLabel>
                <Input
                  required
                  type="date"
                  name="startDate"
                  placeholder="Enter here"
                />
              </Box>
              <Box w="100%">
                <FormLabel>End Date</FormLabel>
                <Input
                  required
                  type="date"
                  name="endDate"
                  placeholder="Enter here"
                />
              </Box>
            </Stack>
            <Box w="100%" mb="10px">
              <FormLabel>Choose Department*</FormLabel>
              <Select
                onChange={e => handleChange(e.target.value)}
                name="department"
                placeholder="Choose option"
              >
                {departments?.map(department => (
                  <option value={department._id}>{department.name}</option>
                ))}
              </Select>
            </Box>
            {selectedId && (
              <Box w="100%" mb="10px">
                <FormLabel>Choose Ward*</FormLabel>
                <Select
                  onChange={e => setWardId(e.target.value)}
                  name="ward"
                  placeholder="Choose option"
                  required
                >
                  {wards?.map(ward => (
                    <option value={ward.wardId}>{ward.wardName}</option>
                  ))}
                </Select>
              </Box>
            )}
            <Box w="100%" mb="10px">
              <FormLabel>Patient Current Location</FormLabel>

              <Box w="100%" className="checkboxFamily">
                <Stack
                  spacing={0}
                  gap="10px"
                  direction={'row'}
                  flexDir={{ base: 'column', xl: 'row' }}
                  justifyContent={'space-between'}
                >
                  <Box w="100%">
                    <Checkbox
                      name="location"
                      colorScheme="teal"
                      value="At Home"
                      isChecked={selectCheckbox === 'At Home'}
                      onChange={handleCheckboxChange}
                    >
                      At Home
                    </Checkbox>
                  </Box>
                  <Box w="100%">
                    <Checkbox
                      name="location"
                      colorScheme="teal"
                      value="Hospitalized"
                      isChecked={selectCheckbox === 'Hospitalized'}
                      onChange={handleCheckboxChange}
                    >
                      Hospitalized
                    </Checkbox>
                  </Box>
                </Stack>
              </Box>
            </Box>

            <Box w="100%" mb="10px">
              <FormLabel>Type of Bed:</FormLabel>

              <Box w="100%" className="checkboxFamily">
                <Stack
                  spacing={0}
                  gap="10px"
                  direction={'row'}
                  flexDir={{ base: 'column', xl: 'row' }}
                  justifyContent={'space-between'}
                >
                  <Box w="100%">
                    <Checkbox
                      colorScheme="teal"
                      name="typeOfBed"
                      value="ICU"
                      isChecked={selectBed === 'ICU'}
                      onChange={handleBed}
                    >
                      ICU
                    </Checkbox>
                  </Box>
                  <Box w="100%">
                    <Checkbox
                      colorScheme="teal"
                      name="typeOfBed"
                      value="Ordinary"
                      isChecked={selectBed === 'Ordinary'}
                      onChange={handleBed}
                    >
                      Ordinary
                    </Checkbox>
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Treatment Cost to be Paid by:</FormLabel>

              <Box w="100%" className="checkboxFamily">
                <Stack
                  spacing={0}
                  gap="10px"
                  direction={'row'}
                  flexDir={{ base: 'column', xl: 'row' }}
                  justifyContent={'space-between'}
                >
                  <Box w="100%">
                    <Checkbox
                      colorScheme="teal"
                      name="costtobepaid"
                      value="Insurance"
                      isChecked={selectPayment === 'Insurance'}
                      onChange={handlePayment}
                    >
                      Insurance
                    </Checkbox>
                  </Box>
                  <Box w="100%">
                    <Checkbox
                      colorScheme="teal"
                      name="costtobepaid"
                      value="Self"
                      isChecked={selectPayment === 'Self'}
                      onChange={handlePayment}
                    >
                      Self
                    </Checkbox>
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>
                Would you like to avail a travel service for your appointment?
              </FormLabel>

              <Box w="100%" className="checkboxFamily">
                <Stack
                  spacing={0}
                  gap="10px"
                  direction={'row'}
                  flexDir={{ base: 'column', xl: 'row' }}
                  justifyContent={'space-between'}
                >
                  <Box w="100%">
                    <Checkbox
                      colorScheme="teal"
                      name="check"
                      value="Yes"
                      isChecked={selectTravel === 'Yes'}
                      onChange={handleTravel}
                    >
                      Yes
                    </Checkbox>
                  </Box>
                  <Box w="100%">
                    <Checkbox
                      colorScheme="teal"
                      name="check"
                      value="No"
                      isChecked={selectTravel === 'No'}
                      onChange={handleTravel}
                    >
                      No
                    </Checkbox>
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box w="100%">
              <FormLabel color={'#208C74'}>Upload Reports</FormLabel>

              <Text fontSize="14px" color="primaryGray.100">
                <Input
                  name="attachment"
                  type="file"
                  id="file-input"
                  display="none"
                  onChange={handleFileChange}
                />
                <Button
                  cursor={'pointer'}
                  as="span"
                  border="1px solid #75767A"
                  bgColor="transparent"
                  fontSize="14px"
                  color="primaryGray.100"
                  transition=".3s"
                  _hover={{
                    bgColor: 'primaryGreen.200',
                    color: '#fff',
                    borderColor: 'primaryGreen.200',
                  }}
                  mr="10px"
                  onClick={handleButtonClick}
                >
                  <AttachmentIcon mr="10px" /> Attach File
                </Button>
                {selectedFile ? selectedFile.name : 'No File chosen'}
              </Text>
            </Box>

            <Box mt="15px">
              <Button
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'16px'}
                fontWeight={500}
                color={'#fff'}
                borderRadius={'25px'}
                h="45px"
                px="80px"
                type="submit"
                isLoading={loading}
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
          {/* {beds.length > 0 && 
            beds.map(bed => ( */}
          <Box w={'40%'}>
            <Text fontSize={'16px'} my={'15px !important'} fontWeight={500}>
              Currently {beds.length || 0} beds available
            </Text>
            <Stack direction={'column'} height={'70vh'} overflowY={'scroll'}>
              {beds
                ?.filter(bed => bed.isAvailable === true)
                ?.map(bed => (
                  <Stack mb={'20px'} w={'100%'}>
                    <Box
                      w={'70%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <HStack alignItems={'center'} gap={6}>
                        <Stack
                          w="70px"
                          h="70px"
                          borderRadius={'12px'}
                          bgGradient={'linear(to-r, #295377, #208C74)'}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Image w="30px" h="30px" src={Check} alt="Check" />
                        </Stack>
                        <Stack>
                          <Text fontSize={'18px'} fontWeight={700}>
                            {bed?.name}
                          </Text>
                          <Text
                            m={'0 !important'}
                            p={'0 !important'}
                            fontSize={'14px'}
                            fontWeight={500}
                          >
                            Available
                          </Text>
                        </Stack>
                        <Stack height={'50px'}>
                          <Text
                            color={'#208C74'}
                            fontSize={'18px'}
                            fontWeight={700}
                          >
                            SAR {bed.price}
                          </Text>
                        </Stack>
                      </HStack>
                      <Stack>
                        <Checkbox
                          colorScheme="teal"
                          value="Hospitalized"
                          isChecked={selectedBedId === bed._id}
                          onChange={() => handleCheckboxBedChange(bed._id)}
                          //   onChange={handleCheckboxChange}
                        ></Checkbox>
                      </Stack>
                    </Box>
                  </Stack>
                ))}
            </Stack>
          </Box>
        </Stack>
      </form>
    </Stack>
  );
}

export default PatientBedBookingForm;
