import React, { useEffect, useState } from 'react';
import MainDashboard from '../../../../components/DashNav/MainDashboard';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  useToast,
  Select,
  Spinner,
} from '@chakra-ui/react';
import DashboardHeader from '../../../../components/Header/DashboardHeader';
import moment from 'moment/moment';
import { GET, POST } from '../../../../utilities/ApiProvider';
import ModalWrapper from '../../../../components/Dashboard/Modal';
import { RiHospitalLine } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
function OccupiedBed({ itemToOccupied, fetchBedDetails, onClose }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      const data = {
        department: itemToOccupied?.department,
        ward: itemToOccupied?.ward,
        occupiedDays: form.get('occupiedDays'),
      };
      const response = await POST(
        `/web/bedbooking/${itemToOccupied?._id}/occupy`,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Occupied Bed SuccessFully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
        fetchBedDetails();
      }
    } catch (error) {
      console.log('Error while occupying the bed', error);
    }
  };
  return (
    <Stack>
      <form onSubmit={handleSubmit} className="applyForm">
        <Box>
          <FormLabel>Occupied Days</FormLabel>
          <Input name="occupiedDays" placeholder="Enter here" type="number" />
        </Box>

        <Button
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          w="100%"
          mb={4}
          mt={2}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          isLoading={loading}
          type="submit"
        >
          Save & continue
        </Button>
      </form>
    </Stack>
  );
}
function ViewBeds() {
  const [selectedService, setSelectedService] = useState('General');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState(null);
  const [itemToOccupied, setItemToOccupied] = useState(null);
  const { id, name } = useParams();
  const fetchBedDetails = async () => {
    try {
      const response = await GET(`/hospital/department/${id}/beds`);
      console.log(response, 'checking');
      if (response.status === 200) {
        setData(response.data);
        setSelectedService(response.data?.[0].name);
      }
    } catch (error) {
      console.log('Error while fetching ward bed detail', error);
    }
  };
  useEffect(() => {
    fetchBedDetails();
  }, [id]);
  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            {name}
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all bed booking details...Check Now!
          </Text>
        </Stack>
      </HStack>

      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Occupied Days'}
        subTitle={'Enter the details to add new bed service'}
        children={
          <OccupiedBed
            itemToOccupied={itemToOccupied}
            fetchBedDetails={fetchBedDetails}
            onClose={onClose}
          />
        }
        isCentered={true}
      />

      <Stack direction={'row'} alignItems={'center'} gap={'20px'}>
        {!data ? (
          <Spinner />
        ) : data?.length > 0 ? (
          data.map(item => (
            <Button
              bgGradient={
                selectedService === item.name &&
                'linear(to-r, #295377, #208C74)'
              }
              bg={'none'}
              fontSize={{ base: '12px', xl: '16px' }}
              fontWeight={500}
              color={
                selectedService === item.name ? '#fff' : 'primaryBlack.100'
              }
              borderRadius={'25px'}
              h={{ base: '35px', xl: '45px' }}
              textTransform={'uppercase'}
              onClick={() => setSelectedService(item.name)}
              _hover={{
                bgGradient: 'linear(to-r, #295377, #208C74)',
                color: '#fff',
              }}
            >
              {item.name}
            </Button>
          ))
        ) : (
          <Heading>No bed found</Heading>
        )}
      </Stack>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {data?.length > 0
          ? data
              .filter(item => item.name === selectedService)
              .map(item => {
                return item?.beds.map(val => {
                  return (
                    <Box
                      shadow={'0px 16px 38px 0px #0000000F'}
                      bg={'#fff'}
                      borderRadius={'16px'}
                      p={4}
                      display={'flex'}
                      flexDirection={'column'}
                      gap={4}
                    >
                      <HStack
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Heading fontWeight={500} fontSize={'20px'}>
                          {val?.name}
                        </Heading>
                      </HStack>

                      <Heading
                        color="#208C74"
                        fontWeight={500}
                        fontSize={'16px'}
                      >
                        SAR {item.price}
                      </Heading>
                      <HStack>
                        <Button
                          w="50%"
                          bgGradient="linear(to-r, #295377, #208C74)"
                          color="#E9E9E9"
                          fontWeight={'500'}
                          fontSize={'15px'}
                          borderRadius={'21px'}
                          border={'2px solid transparent'}
                          _hover={{
                            bgGradient: 'none',
                            borderColor: 'primaryGreen.200',
                            color: 'primaryGreen.200',
                          }}
                          disabled={val?.isAvailable === false}
                        >
                          Available
                        </Button>
                        <Button
                          w="50%"
                          shadow={'0px 16px 38px 0px #0000000F'}
                          border={'1px solid #75767A'}
                          color="#75767A"
                          fontWeight={'500'}
                          fontSize={'15px'}
                          borderRadius={'21px'}
                          onClick={() => {
                            setItemToOccupied(val);
                            onOpen();
                          }}
                        >
                          Occupied
                        </Button>
                      </HStack>
                    </Box>
                  );
                });
              })
          : null}
      </Grid>
    </Stack>
  );
}

export default ViewBeds;
