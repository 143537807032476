// export const baseURL = 'http://45.55.137.96:3001/api';
// export const baseURL = 'http://localhost:3000/api';
export const baseURL = 'https://emedst.com/api';
// export const baseURL = 'https://e-medical.thewebtestlink.xyz/api';
// export const baseURL = 'http://localhost:3001/api';
// export const imgUrl = "http://45.55.137.96:3000";
// export const baseURL = 'httts://emedst.com/api';

// export const imgUrl = 'http://localhost:3001/';
export const imgUrl = 'https://emedst.com/';
// export const imgUrl = 'https://e-medical.thewebtestlink.xyz/';
export const token = JSON.parse(localStorage.getItem('emediiUser') ?? '{}');
export const MOYASAR_KEY = 'pk_live_SakLgxFDyNeX45yKU4VkcNkj5BwY6eKdxq6HtMuV';
